/** vendor */
import React, { useState } from 'react'

const ImportedRecipeDisclaimerDialog = ({ onConfirm }) => {
  const [isOpen, setIsOpen] = useState(true)

  const handleConfirm = () => {
    setIsOpen(false)
    onConfirm()
  }

  return (
    <div
      className={`fixed z-40 inset-0 p-2 overflow-y-auto ${
        isOpen ? 'block' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-30"></div>
      
      <div className="z-50 fixed bottom-0 left-0 w-full">
        <div className="bg-an_white-dark rounded-md shadow-md max-w-2xl mx-auto">
          <h2 className="w-full text-xl text-an_white text-center items-center p-4 bg-an_green-dark rounded-t">
            Imported Recipe Disclaimer
          </h2>
          <p className="text-an_green-dark p-4">
            <strong>Accuracy & Missing Information</strong><br /><br />
            Because we only pull publicly shared data, some valuable details may not be included. 
            <br />
            For the best experience, we highly recommend checking the original source for complete and accurate information.
            <br /><br />
            <strong>Liability</strong><br /><br />
            aisle nine is not responsible for errors, omissions in the recipe, nor liable for any injuries or damages that could occur from following the recipe.
            Always sense check ingredients, allergens, and cooking methods before use.
            <br /><br />  
            <strong>Copyright Notice</strong><br /><br />
            All recipes remain the property of their original creators.<br /> 
            If you enjoy a recipe, please support the original author by visiting their website.
            <br /><br />  
          </p>
        </div>
        <button
          className="rounded p-2 my-3 mx-auto block w-56 border border-an_orange-darker3 text-an_white bg-an_orange text-border-orange" 
          onClick={handleConfirm}
        >
          Ok
        </button>

      </div>
    </div>
  )
}

export default ImportedRecipeDisclaimerDialog
