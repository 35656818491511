/** vendor */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'

/** components */
import LoginForm from '../../components/Forms/LoginForm'
import useOrientation from '../../hooks/useOrientations'
import Logo from '../../components/UI/Logo'

export default function Login() {
    const navigate = useNavigate()
    const { type } = useOrientation()
    const is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1

    return(
        <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-an_green-dark">
            { (type || is_safari) && 
                <div className="flex flex-col justify-around h-screen w-screen overflow-auto">
                    <div className={`flex flex-1 items-center justify-center w-full mx-auto flex-col`}>
                        <Logo />
                        <div className="w-full max-w-xl">
                            <LoginForm />
                        </div>
                    </div>
                </div>
            }

            <button 
                className="h-6 text-an_white absolute bottom-2 left-1 flex text-xs items-center"
                onClick={() => navigate('/home') }
            >
                <ChevronLeftIcon className="w-6" /> back
            </button>
        </div>
    )
}