/** vendor */
import React, { useState } from 'react'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'

/** components */
import RecipeList from '../../components/Recipes/RecipeList'

export default function Recipes() {
    const [loading, setLoading] = useState(false)
    const [tab, setTab] = useState('verified')
  
    const handleToggleTab = (e) => {
      e.preventDefault()
      setTab(e.target.href.split('#')[1])
    }
  
    return (
        <div className="pt-12 w-full md:max-w-4xl">
            <Card title="Discover" autoHeight="true">
              
              <div className="w-full flex flex-col items-center" style={{ height: 'calc(100vh - 175px)' }}>
                <ul className="w-full flex justify-center bg-an_white-light flex-wrap text-sm font-medium text-center border-b-2 border-an_orange-dark pt-2 px-2">
                    <li className="w-1/3 text-center">
                        <a 
                          href="#verified" 
                          aria-current="page"
                          onClick={handleToggleTab}  
                          className={`${tab === 'verified' ? 'active bg-an_orange border border-an_orange-dark border-b-0 text-border-orange text-an_white': 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                        >
                            Verified
                        </a>
                    </li>
                    <li className="w-1/3 text-center">
                        <a 
                          href="#community"
                          onClick={handleToggleTab} 
                          className={`${tab === 'community' ? 'active bg-an_orange border border-an_orange-dark border-b-0 text-border-orange text-an_white': 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                        >
                            Community
                        </a>
                    </li>
                    <li className="w-1/3 text-center">
                        <a 
                          href="#recommended"
                          onClick={handleToggleTab} 
                          className={`${tab === 'recommended' ? 'active bg-an_orange border border-an_orange-dark border-b-0 text-border-orange text-an_white': 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                        >
                          Recommended
                        </a>
                    </li>
                </ul>
    
                { 
                  tab === 'verified' && 
                    <div className="w-full flex justify-center">
                      <RecipeList />
                    </div> 
                }

                { 
                  tab === 'community' && 
                    <div className="text-center mt-8 opacity-60">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 mx-auto block">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                        </svg>
                        <p>Coming Soon.</p>
                    </div>
                }

                { 
                  tab === 'recommended' && 
                    <div className="text-center mt-8 opacity-60">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 mx-auto block">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                      </svg>
                      <p>Coming Soon.</p>
                    </div>
                }
              </div>
            </Card>
        </div>
    )
}