/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/outline'

/** lib */
import Loading from '../UI/Loading'
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** state */
import { getSavedRecipes } from '../../actions/user.actions'
import { removeSavedRecipe } from '../../services/user.service'

/** components */
import RecipeListItem from './RecipeListItem'

export default function SavedRecipeList() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const recipes = useSelector(state => state?.user_data?.saved_recipes)
  
    useEffect(() => {
        setLoading(true)

        dispatch(getSavedRecipes()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [])

    const handleClick = (recipeId) => {
        navigate(`/recipes/${recipeId}`)
    }   

    const handleRemoveSavedRecipe = (savedRecipe) => {
        setLoading(true)

        removeSavedRecipe(savedRecipe.recipe_id).then(() => {
            dispatch(getSavedRecipes()).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        })
    }

    if(loading) {
        return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
    }

    return (
        <div className="w-full h-[70vh]">
            <section className="antialiased text-gray-600">
                <div className="flex flex-col items-center justify-center">
                    <div className="w-full">
                        <div className="overflow-x-auto">
                            { 
                                (recipes) ?
                                    recipes.map((recipe, index) => {
                                        const recipeImage = (recipe.image) ? `${IMG_URL}/${recipe.image}` : PLACEHOLDER_IMG

                                        return (
                                            <div className="w-full py-3 px-2 cursor-pointer odd:bg-an_white-dark hover:bg-an_white-darker1" key={index}>

                                                
                                                <div className="flex w-full justify-end items-center">
                                                    <button
                                                        className="text-xs w-5 ml-1 text-black"
                                                        onClick={() => { handleRemoveSavedRecipe(recipe) }}
                                                    >
                                                        <XCircleIcon className="w-5 h-5 text-an_green-dark" />
                                                    </button>
                                                </div>
                                                
                                                <div className="flex-1 flex cursor-pointer" 
                                                    onClick={() => { handleClick(recipe?.recipe_id) }}
                                                >    
                                                    {
                                                        recipeImage && 
                                                            <div className="flex-shrink-0">
                                                                <img src={recipeImage} className="w-14 lg:w-40 mt-2 rounded border border-an_green-dark"/>
                                                            </div>
                                                    }

                                                    <div className="flex-grow pl-4">                                                
                                                        <div className="text-an_green-dark text-xl">{ recipe.name }</div>
                                                        <small className="text-left text-an_green-dark block text-xs">{recipe.category?.name}</small>
                                                        <div className="text-left flex-wrap flex-shrink-1 py-1 text-an_green-dark">{recipe.description}</div>
                                                        <small className="text-left text-an_green-dark pb-2 block">Serves: {recipe.serves}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <p className="text-center">No Recipes Found</p>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}