import React from 'react'

const SectionHeading = ({ 
    label, 
    justify = 'justify-between',
    bg = 'bg-an_white-light' 
}) => {
  return (
    <div className="py-2 mt-8 mb-4 h-5">
        <hr className="border-an_green-dark" />
        <div className={`flex text-an_green-dark ${justify} px-2 mt-[-14px]`}>
            <span className={`text-base block ${bg} px-1 font-bold`}>{label}</span>
        </div>
    </div>
  )
}

export default SectionHeading;


