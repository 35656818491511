/** vendor */
import React, { useState } from 'react'

const StepZeroWelcome = () => {
  return (
    <div>      
      <p className="text-black text-base text-center italic pb-4">everything culinary, all in one place</p>

      <ul className="text-black text-sm pt-4 list-disc">
        <li className="py-1">discover recipes and learn to cook</li>
        <li className="py-1">design meal plans and generate grocery lists</li>
        <li className="py-1">create, curate and import your favorite recipes from around the internet</li>
        <li className="py-1">publish your own creations and become culinary celebrity</li>
        <li className="py-1">promote your brand or business</li>
        <li className="py-1">manage stores, minimize waste, reduce grocery costs</li>
        <li className="py-1">collaborate with colleagues, friends, family and flatmates</li>
        <li className="py-1">keep to your budget and find out if you're paying a fair price</li>
        <li className="py-1">log a food diary and nuturional intake</li>
      </ul>      
    </div>
  )
}

export default StepZeroWelcome