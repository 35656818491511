/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import Loading from '../../UI/Loading'

/** state */
import { 
  getSavedRecipes,
  getUserMealplans, 
  getShoppinglists, 
  getSharedWithMe,
  getUserRecipes 
} from '../../../actions/user.actions'

const StepThreeShoppingLists = () => {
  const dispatch = useDispatch()

  const [creatingShoppinglist, setCreatingShoppinglist] = useState(false)
  const [creatingMealplan, setSaveFilters] = useState(false)
  const [mealplan, setMealplan] = useState(undefined)
  const [shoppinglist, setShoppinglist] = useState(undefined)
  const savedRecipes = useSelector(state => state?.user_data?.saved_recipes)

  const [mealplanFormData, setMealplanFormData] = useState({
    name: 'My first meal plan',
    start_date: new Date().toISOString(),
    end_date: new Date(new Date().setDate(new Date().getDate() + 2)).toISOString(),
    number_of_days: 3,
    recipes: []
  })

  const [shoppinglistFormData, setShoppinglistFormData] = useState({
    name: 'My first shoppinglist',
    ingredients: []
  })

  const getListsAndPlans = async () => {
    dispatch(getUserMealplans())
    dispatch(getShoppinglists())
  }

  const createShoppinglist = async () => {

  }

  const createMealPlanlist = async () => {
  }

  const assignRecipesToMealplan = async () => {
    let counter = 0
    let max = mealplanFormData.number_of_days

    // @todo this is wrong
    for(const recipe of savedRecipes) {
      if(counter < max) {
        mealplanFormData.recipes.push({
          "date": new Date(new Date().setDate(new Date().getDate() + counter)).toISOString(),
          "day": counter,
          "recipes": [
            {
              "name": recipe?.name,
              "value": recipe?.recipe_id,
              "image": recipe?.image
            }
          ]
        })

        counter++
      } else {
        break;
      }
    }

    console.warn('assignRecipesToMealplan', mealplanFormData)
  }

  const assignIngredientsToShoppinglist = async () => {
    const formattedIngredients = []

    let counter = 0
    let max = mealplanFormData.number_of_days

    for(const recipe of savedRecipes) {

      if(counter < max) {

        for(const ingredient of recipe?.recipe_ingredients) {
          formattedIngredients.push({
            "quantity": ingredient?.quantity,
            "value": ingredient?.ingredient?.ingredient_id,
            "name": ingredient?.ingredient?.name,
            "image": ingredient?.ingredient?.image,
            "category_id": ingredient?.ingredient?.category_id,
            "default_quantity": ingredient?.ingredient?.default_quantity,
            "default_unit": ingredient?.ingredient?.default_unit,
            "estimated_expiry_days": ingredient?.ingredient?.estimated_expiry_days,
            "checked": false
          })
        }

        counter++
      } else {
        break;
      }
    }

    // @todo merge ingredients
    console.warn('formattedIngredients', formattedIngredients)
  }

  /**
   * Hooks
   */
  useEffect(() => {
    dispatch(getSavedRecipes())
  }, [])

  useEffect(() => {
    if(savedRecipes?.length > 0) {
      console.warn('savedRecipes', savedRecipes)
      assignRecipesToMealplan()
      assignIngredientsToShoppinglist()
    }
  }, [savedRecipes])

  return (
    <div>
      <p className="text-black text-base text-center italic pb-4">
        Next we'll generate a grocery list and meal plan for your selections. 
      </p>
      <div>
        {
          creatingShoppinglist ? 
              <div className="h-[30vh] relative pt-40">
                  <Loading />
              </div> :
              <div className="h-[30vh]">
                <div className="text-center opacity-60">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 mx-auto block">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                  </svg>
                  <p>work in progress<br />create my first shopping list.</p>
                </div>
              </div>
        }
      </div>

      <div>
        { 
          creatingMealplan ? 
              <div className="h-[30vh] relative pt-40">
                  <Loading />
              </div> :
              <div className="h-[30vh]">
                <div className="text-center opacity-60">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 mx-auto block">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                  </svg>
                  <p>work in progress<br />create my first mealplan.</p>
                </div>
              </div>
        }
      </div>
    </div>
  )
}

export default StepThreeShoppingLists