/** vendor */
import React, { useEffect, useState } from 'react'

import { dateDiff } from '../../utils'
import SectionHeading from '../UI/SectionHeading'

const CreateMealplanDialog = ({ 
  showDialog = false, 
  onSubmit = () => {}, 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    start_date: '',
    end_date: '',
    number_of_days: 0
  })

  const validateName = () => {
    const validNamePattern = /^[a-zA-Z0-9\s]+$/;
    return formData.name && validNamePattern.test(formData.name);
  }

  const validateForm = () => {
    if(!validateName()) return false
    
    if(formData?.start_date?.length > 0 && !formData?.end_date) {
      return false
    }

    if(new Date(formData?.end_date) < new Date(formData?.start_date)) {
      return false
    }

    return true
  }

  /**
   * Hooks
   */
  useEffect(() => {    
    setIsOpen(showDialog) 
  }, [showDialog])

  /**
   * Handlers
   */
  const handleNameChange = (e) => {
    const formCopy = {...formData}
    formCopy[e.target.name] = e.target.value

    setFormData(formCopy)
  }

  const handleDateChange = (e) => {
    const formCopy = {...formData}
    formCopy[e.target.name] = e.target.value

    if(formCopy.start_date && formCopy.end_date) {
      formCopy.number_of_days = dateDiff(formCopy.start_date, formCopy.end_date) + 1
    }

    setFormData(formCopy)
  }

  const handleDaysChange = (e) => {
    const formCopy = {...formData}
    formCopy[e.target.name] = e.target.value

    setFormData(formCopy)
  }

  const handleCancel = () => {
    setFormData({
      name: '',
      start_date: '',
      end_date: '',
      number_of_days: 0
    })

    onCancel()
  }

  const handleCreate = () => {
    onSubmit(formData)
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>

      <div className="fixed bottom-0 left-0 w-full px-2">
        <div className="bg-an_white-dark rounded shadow-md w-full md:max-w-xl mx-auto z-50">
          <h2 className="w-full text-xl text-an_white text-center items-center p-4 bg-an_green-dark rounded-t">
            create a mealplan
          </h2>

          <div className="flex min-w-full justify-center p-4"> 
            <div className="w-full">
                <div className="w-full">
                    <label 
                    htmlFor="name" 
                    className="block mb-2 text-base text-an_green-dark">name</label>
                    <div className="relative">
                        <input 
                            type="text" 
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleNameChange} 
                            className={
                              `${formData.name.length > 0 && !validateName() ? 'border-an_orange-dark' : ''} rounded`
                            }                            
                            placeholder="Plan Name"
                        />
                    </div>
                    <label className="block text-xs border-an_orange-dark mt-1">
                        { 
                            formData.name.length > 0 && !validateName()
                                ? 'No special characters allowed' 
                                : <>&nbsp;</>
                        }
                    </label>
                </div>

                <div className="w-full">
                    <label 
                        htmlFor="days" 
                        className="block mb-2 text-base text-an_green-dark"
                    >start date</label>
                    <input
                        className={
                          `border rounded w-full text-base`
                        }                         
                        onChange={(e) => { handleDateChange(e)}}
                        type="date" 
                        id="start_date" 
                        name="start_date" 
                        value={formData.start_date} 
                        max={formData.end_date} 
                    />
                </div>

                <div className="w-full mt-1">
                    <label 
                        htmlFor="days" 
                        className="block mb-2 text-base"
                    >end date</label>
                    <input
                        className={
                          `${formData?.number_of_days < 1 && (formData.start_date.length > 0 && !formData.end_date) ? 'border-an_orange-dark' : ''} border rounded w-full text-base`
                        }        
                        onChange={(e) => { handleDateChange(e)}}
                        type="date" 
                        id="end_date" 
                        name="end_date" 
                        value={formData.end_date} 
                        min={formData.start_date} 
                    />
                    <label className="block text-xs font-light text-red-500 mt-1">
                        { 
                            formData?.number_of_days < 1 && (formData.start_date.length > 0 && !formData.end_date)
                                ? 'An end date is required' 
                                : <>&nbsp;</>
                        }
                    </label>
                </div>

                <SectionHeading label="or" justify="justify-center" bg="bg-an_white-dark"  />

                <div className={`${ formData.end_date || formData.start_date ? 'opacity-50' : '' } mx-auto block w-[150px]`}>
                    <label htmlFor="days" className="block mb-2 text-base text-center text-an_green-dark">number of days</label>
                    <input 
                      type="number"
                      id="number_of_days"
                      name="number_of_days" 
                      className="w-14 appearance-none rounded text-center block mx-auto" 
                      placeholder=""
                      value={formData?.number_of_days}
                      disabled={(formData.end_date || formData.start_date)}
                      onChange={handleDaysChange} 
                    />
                  </div>
            </div>
          </div>                
        </div>

        <div className="flex justify-center w-full py-3 md:max-w-xl mx-auto">
          <button
            className="px-5 py-2 rounded w-36 text-an_green-dark bg-an_white mr-1"
            onClick={handleCancel}
          >
            cancel
          </button>

          <button
            disabled={!validateForm()}
            className={`${!validateForm() ? 'opacity-60' : ''} px-5 ml-1 py-2 relative rounded w-36 border border-an_orange-darker3 text-an_white bg-an_orange text-border-orange`}
            onClick={handleCreate}
          >create</button>
      </div>
      </div>
    </div>
  )
}

export default CreateMealplanDialog
