/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HeartIcon, RefreshIcon } from '@heroicons/react/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/solid'

/** state */
import { createSavedRecipe, deleteSavedRecipe } from '../../actions/user.actions'
import { getSavedRecipes } from '../../actions/user.actions'

export default function SaveRecipeForm({ recipe_id, onUpdate }) {
    const dispatch = useDispatch()

    const { isLoggedIn } = useSelector(state => state.auth)
    const savedRecipes = useSelector(state => state?.user_data?.saved_recipes)

    const [saved, setSaved] = useState(false)
    const [loading, setLoading] = useState(false)
    
    /**
     * Hooks
     */
    useEffect(() => {
        if (savedRecipes) {
            setSaved(savedRecipes.map(r => r.recipe_id).includes(recipe_id))
        }
    }, [savedRecipes])

    /**
     * Handlers
     */
    const handleToggleSave = async e => {
        e.preventDefault()

        setLoading(true)

        if (!saved) {
            dispatch(createSavedRecipe({recipe_id})).then((r) => {
                console.warn('Save successful')
                setSaved(true)
                setLoading(false)
                // onUpdate(recipe_id)
            }).catch((error) => {
                console.error('Error in dispatch:', error)
                setLoading(false)
            })
        } else {
            dispatch(deleteSavedRecipe(recipe_id)).then((r) => {
                console.warn('delete successful')
                setSaved(false)
                setLoading(false)
                // onUpdate(recipe_id)
            }).catch((error) => {
                console.error('Error in dispatch:', error)
                setLoading(false)
            }) 
        }
    }

    if (!isLoggedIn) {
        return (<></>)
    }

    return (
        <div className="relative">
            <button
                disabled={loading} 
                onClick={(e) => { handleToggleSave(e) }}
                className="h-6 w-6" 
                type="button"
            >
                { loading && <RefreshIcon className="animate-spin opacity-80" /> }
                { saved && !loading && <HeartIconSolid className="text-an_orange-darker1" /> }
                { !saved && !loading && <HeartIcon /> }
          </button>
        </div>
    )

}