/** vendor */
import React, { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'

const OptionDialog = ({ 
  headline = <></>,
  showDialog, 
  title, 
  options, 
  processing, 
  onSelect = () => {} , 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(true)

  /**
   * Hooks
   */
  useEffect(() => { setIsOpen(showDialog) }, [showDialog])

  /**
   * Handlers
   */
  const handleOptionSelect = (option) => {
    onSelect(option)
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>
                                            
      <div className="fixed bottom-3 w-[95vw] z-50 md:max-w-xl mx-auto">
        <button
            className="rounded z-50 w-8 h-8 mb-2 bg-an_green-dark text-an_white block ml-auto"
            onClick={handleCancel }
        >
          <XIcon className="w-6 h-6 block mx-auto" />
        </button>

        <div className="bg-an_white-dark rounded">
          <h2 className="w-full text-xl font-semibold text-an_white text-center p-4 bg-an_green-dark rounded-t">
            {title}
          </h2>

          { headline }
  
          <ul className="space-y-4 p-4 max-w-lg mx-auto">
            {options.map((option) => (
              <li key={option.id}>
                <button
                  disabled={option?.disabled || false} 
                  className={
                    `border 
                    ${option.id === 'delete' || option.id === 'remove'
                      ? 'border-an_orange-dark border bg-an_orange text-an_white text-border-orange' : 
                      'bg-an_white  text-an_green-dark border border-an_green'
                    } 
                    rounded block w-full lowercase py-2 ${option?.disabled ? 'opacity-20' : ''}`
                  }               
                  onClick={() => handleOptionSelect(option?.id)}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>

        </div>
      </div>    
    </div>
  )
}

export default OptionDialog