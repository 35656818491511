/** vendor */
import React, { useState, useEffect, } from 'react'
import { PlayIcon, PauseIcon } from '@heroicons/react/solid'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import { ChevronUpIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ClockIcon } from '@heroicons/react/outline'
import Timer from '../UI/Timer'
import RecipeStep from './RecipeStep'


export default function RecipeCookMode({ recipe, onToggleWakeLock }) {
    const [currentStep, setCurrentStep] = useState(0)
    const [timers, setTimers] = useState([])
    const [showIngredients, setShowIngredients] = useState(false)
    
    const displayFormat = (value) => { 
        return value.split(':').map(i => i.length < 2 ? '0' + i : i).join(':')
    } 

    useEffect(() => {
        if(recipe?.current_recipe?.recipe_steps) {
            const initalTimers = []
            
            recipe?.current_recipe?.recipe_steps.forEach((step, index) => {
                if(step?.timer) {
                    initalTimers.push({
                        start_time: step.timer,
                        time_remaining: step.timer,
                        paused: true,
                        step_number: index
                    })
                } else {
                    initalTimers.push({
                        start_time: null,
                        time_remaining: null,
                        paused: true,
                        step_number: index
                    })
                }
            })

            setTimers(initalTimers)

            return () => {
                onToggleWakeLock(false)
            }
        }
    }, [recipe])

    const handleOnClickTimer = (enabled) => {
        onToggleWakeLock(enabled)
    }

    return (
        <div className="w-full flex justify-center relative">
            <div className="flex flex-col w-full">
                <div className="w-full">
                    <div className="flex w-full pb-4 overflow-y-scroll">
                        <div className="flex" style={{ width: `calc(25% * ${timers?.length || 0})` }}>
                            { 
                                recipe.current_recipe.recipe_steps
                                    .sort((a, b) => a.step_order > b.step_order ? 1 : -1)
                                    .map((s, i) => 
                                        <div 
                                            className={`
                                                ${i !== currentStep ? 'border  bg-an_white text-an_green-dark' : 'bg-an_green-dark text-an_white mx-1'} 
                                                p-1 text-xs text-center w-[25vw] border-an_green-dark rounded`
                                            }
                                            onClickTimer={handleOnClickTimer}
                                            key={i}
                                        >
                                            <button 
                                                onClick={ () => { }}
                                                className="block text-center w-full"
                                            >{i+1}</button>
                                            <button 
                                                onClick={ () => {  }}
                                                className="block text-center w-full"
                                            >
                                                {
                                                    timers[i]?.start_time && 
                                                        <Timer 
                                                            onClickTimer={handleOnClickTimer} 
                                                            timerObj={timers[i]} 
                                                            restartTimer={false} 
                                                            pauseTimer={true} 
                                                        />
                                                }
                                            </button>
                                        </div>
                                )
                            }
                        </div>
                    </div>

                    <div className="mt-2">
                        { 
                            recipe.current_recipe.recipe_steps
                                .sort((a, b) => a.step_order > b.step_order ? 1 : -1)
                                .map((step, i) => {
                                    const stepImage = (step.image) ? `${IMG_URL}/${step.image}` : PLACEHOLDER_IMG
                                    const ingredientList = [...recipe.current_recipe.recipe_ingredients]

                                    return <div key={i}>
                                        {
                                            i === currentStep && 
                                                <RecipeStep 
                                                    step={step} 
                                                    key={step.recipe_step_id} 
                                                    ingredientList={recipe.current_recipe.recipe_ingredients}
                                                />
                                        }
                                    </div>
                                }
                            )
                        }
                    </div>
                </div>
            </div>

            <div className="fixed bottom-0 left-0 py-3 flex justify-between px-2 w-full">
                <div className="flex flex-col items-center mx-auto">
                    <span className="text-xs text-an_white-darker3 leading-none block pt-1">
                        step <span className="font-semibold text-an_green-darker">
                            {currentStep + 1}</span> of <span className="font-semibold text-an_green-darker">{recipe.current_recipe.recipe_steps?.length}</span> steps
                    </span>
                    <div className="inline-flex mt-2 xs:mt-0">
                        <button 
                            disabled={currentStep < 1} 
                            onClick={()=> { if (currentStep !== 0) setCurrentStep(currentStep - 1) }} 
                            className={`
                                ${currentStep < 1 ? 'opacity-50' : ''}
                                flex items-center justify-center px-3 h-8 text-sm font-medium 
                                text-white bg-an_green-dark rounded-l border border-an_green-dark mr-[1px]
                            `}
                        >
                        Prev
                        </button>
                        <button 
                            disabled={currentStep === recipe?.current_recipe?.recipe_steps?.length - 1}
                            onClick={()=> { if (currentStep < recipe?.current_recipe?.recipe_steps?.length - 1) { setCurrentStep(currentStep + 1) }}}                    
                            className={`
                                ${currentStep === recipe?.current_recipe?.recipe_steps?.length - 1 ? 'opacity-50' : ''}
                                flex items-center justify-center px-3 h-8 text-sm font-medium 
                                text-white bg-an_green-dark border border-an_green-dark rounded-r
                            `}
                        >
                        Next
                        </button>
                    </div>
                </div>
            </div>
        </div>  
    )
}