/** vendor */
import React, { useState } from 'react'

const StepFourBetaNotice = () => {
  return (
    <div>
      <p className="text-black text-sm text-center italic py-4">
        Your time and feedback is important to us so here some perks!
      </p>

      <ul className="text-black text-sm py-8 pl-8 list-disc">
        <li className="py-1">business tier for life</li>
        <li className="py-1">competitions and prizes</li>
        <li className="py-1">first in feature previews</li>
        <li className="py-1">feature previews and launch party invites</li>
      </ul>

      <p className="text-black text-sm text-center italic py-4">
        please report any bugs or suggest improvements via the contact form, enjoy :)
      </p>
    </div>
  )
}

export default StepFourBetaNotice