/** vendor */
import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { QuestionMarkCircleIcon } from '@heroicons/react/solid'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import IngredientsDropdown from '../../components/UI/IngredientsDropdown'

/** components */
import ImportedRecipeDetail from '../../components/Recipes/ImportedRecipeDetail'
import ImportedRecipeDisclaimerDialog from '../../components/Dialogs/ImportedRecipeDisclaimerDialog'

export default function ImportedRecipe() {
  const [loading, setLoading] = useState(false)
  const [showDisclaimerDialog, setshowDisclaimerDialog] = useState(false)


  const location = useLocation()

  const import_recipe_id = location.pathname.split('/')[2]

  const importedRecipe = useSelector(state => state.user_data.imported_recipes
      .find(r => r.import_recipe_id === parseInt(import_recipe_id))
  )

  const [recipeJson, setRecipeJson] = useState(null)
  
  /**
   * Hooks
   */
  useEffect(() => {
      setLoading(true)
      try {
          setRecipeJson(JSON.parse(importedRecipe.data))
      } catch (error) {
          console.warn(error)
      }
      setLoading(false)
  }, [importedRecipe])

  return (
    <div className="pt-12 w-full">
        <div className="w-full md:max-w-4xl mx-auto">
            <Card 
                title={recipeJson?.name}
                status={
                    <button
                        className="text-xs h-6 w-6 text-an_white rounded-full"
                        onClick={ (e) => { setshowDisclaimerDialog(true) } }
                    >
                        <QuestionMarkCircleIcon className="w-6 h-6" />
                    </button>
                }
            >
                    {
                        !recipeJson && <div className="h-[60vh] relative pt-40"><Loading /></div>
                    }

                    {
                        recipeJson && <ImportedRecipeDetail recipe={recipeJson}/>
                    }
                
            </Card>
        </div>

        {
            recipeJson && !showDisclaimerDialog &&
                <IngredientsDropdown 
                    ingredients={
                        recipeJson?.recipeIngredient?.map((i, index) => {
                            return {
                                value: 'IMPORT_INGREDIENT_' + index,
                                name: i,
                            }

                        })
                    } 
                />
        }

        {
            showDisclaimerDialog &&
                <ImportedRecipeDisclaimerDialog 
                    onConfirm={ () => { setshowDisclaimerDialog(false) } }
                />
        }
    </div>
  )
}