/** vendor */
import React, { useEffect, useState } from 'react'

/** state */
import { createShoppinglist } from '../../services/user.service'

const CreateShoppingListDialog = ({ 
  showDialog = false,
  onCreate = () => {}, 
  onCancel = () => {} 
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  const [shoppinglistName, setShoppinglistName] = useState('')

  /**
   * Hooks
   */
  useEffect(() => {    
    setIsOpen(showDialog) 
  }, [showDialog])

  /**
   * Handlers
   */
  const handleChange = (e) => {
    setShoppinglistName(e.target.value)
  }

  const handleCancel = () => {
    setShoppinglistName('')
    setIsOpen(false)
    onCancel()
  }

  const validateName = () => {
    const validNamePattern = /^[a-zA-Z0-9\s]+$/;
    return shoppinglistName && validNamePattern.test(shoppinglistName);
  }

  const handleCreate = () => {
    setSaving(true)

    createShoppinglist({
        name: shoppinglistName,
        ingredients: []
    })
    .then((r) => {
        setSaving(false)
        onCreate(r?.user_shoppinglist_id)
    }).catch(() => {
        setSaving(false)
    })
  }

  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-60"></div>

      <div className="fixed bottom-0 left-0 w-full px-2">
        <div className="bg-an_white-dark rounded shadow-md w-full md:max-w-xl mx-auto z-50">
          <h2 className="w-full text-xl text-an_white text-center items-center p-4 bg-an_green-dark rounded-t">
            create a list
          </h2>

          <div className="flex min-w-full justify-center p-4"> 
              <div className="w-full">
                  <div className="mb-1 w-4/5 mx-auto">
                      <label htmlFor="shoppinglistName" className="block mb-2 text-base text-an_green-dark">name</label>
                      <div className="relative">
                          <input 
                              disabled={saving}
                              type="text" 
                              id="shoppinglistName"
                              value={shoppinglistName}
                              onChange={handleChange} 
                              className={
                                `${shoppinglistName.length > 0 && !validateName() ? 'border-an_orange-dark' : ''} border rounded w-full text-xs py-2 px-3 text-black`
                              }                              
                              placeholder="my shopping list"
                          />
                      </div>
                      <label className="block text-an_orange-dark mt-1">
                          { 
                              shoppinglistName.length > 0 && !validateName()
                                  ? 'No special characters allowed' 
                                  : <>&nbsp;</>
                          }
                      </label>
                  </div>
              </div>

          </div>
        </div>

        <div className="flex justify-center w-full py-3 md:max-w-xl mx-auto">
            <button
              disabled={saving}
              className="px-5 py-2 rounded w-36 text-an_green-dark bg-an_white mr-1"
              onClick={handleCancel}
            >
              cancel
            </button>

            <button
              disabled={saving || !validateName()}
              className={`${!validateName() ? 'opacity-60' : ''} px-5 ml-1 py-2 relative rounded w-36 border border-an_orange-darker3 text-an_white bg-an_orange text-border-orange`}
              onClick={handleCreate}
            >
              <span>create</span> 
              {
                saving && 
                  <div className="animate-pulse absolute right-2 top-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </div> 
              }
            </button>
        </div>
      </div>




    </div>
  )
}

export default CreateShoppingListDialog
