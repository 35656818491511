/** vendor */
import React, { useEffect, useState } from 'react'
import { XCircleIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/outline'
import { PlusCircleIcon } from '@heroicons/react/solid'

/** lib */
import { useNavigate } from 'react-router-dom'
import { useDebounce } from '../../hooks/useDebounce'

/** components */
import ConfirmDialog from '../Dialogs/ConfirmDialog'
import SearchSelectForm from '../Forms/SearchSelectForm'
import SectionHeading from '../UI/SectionHeading'

const MealplanCreator = ({ 
    disableButtons = false, 
    rows,
    recipeOptions = [], 
    onUpdateMeaplanRecipe, 
    onDeleteMeaplanRecipe, 
    startDate = null,
    saving = false 
}) => {
    const navigate = useNavigate()

    const [selectedMealplanRecipe, setSelectedMealplanRecipe] = useState({})
    const [mealplanRows, setMealplanRows] = useState([])

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    
    const [focusRecipeSelection, setFocusRecipeSelection] = useState(false)
    const [focusedRowIndex, setFocusedRowIndex] = useState(0)
    const [rowsInit, setRowsInit] = useState(false)
    
    useEffect(() => {
        setMealplanRows(rows || [])
    }, [rows, recipeOptions])

    useDebounce(() => {
        if(rowsInit) { 
            handleSaveMealplan(mealplanRows, true) 
        } else {
            setRowsInit(true)
        }
    }, [mealplanRows], 500)
    
    const addMealplanRecipe = (recipe, rowIndex) => {
        if (recipe) {
            const updateRowRecipes = [...mealplanRows]
            updateRowRecipes[rowIndex]?.recipes.push(recipe)
            setMealplanRows(updateRowRecipes)
        }
        // if(onUpdateMeaplanRecipe) {
        //     onUpdateMeaplanRecipe(mealplanRows, false)
        // }
    }

    const handleCreateCustomItem = (option, rowIndex) => {
        const customRecipe = {
            name: option?.name,
            value: null,
            image: null
        }
        addMealplanRecipe(customRecipe, rowIndex)
    }

    // const handleMealplanRecipeChange = (event, rowIndex) => {
    //     setSelectedMealplanRecipe(recipeOptions[event.target.value])


    //     if(recipeOptions[event.target.value]) {
    //         addMealplanRecipe(recipeOptions[event.target.value], rowIndex)
    //     }
    // }

    const handleRemoveMealplanRecipe = (rowIndex, recipeIndex) => {
        const rowsCopy = [...mealplanRows]
        const rowToUpdate = mealplanRows[rowIndex]
       
        rowToUpdate.recipes.splice(recipeIndex, 1)
        rowsCopy[rowIndex] = rowToUpdate

        setMealplanRows(rowsCopy)
    }

    const deleteMealplan = () => {
        if (onDeleteMeaplanRecipe) {
            onDeleteMeaplanRecipe()
        }    
    }

    const handleDeleteMealplan = () => {
        setShowConfirmDelete(true)
    }

    const handleCancelMealplanEdit = () => {
        navigate('/user-meal-plans')
    }

    const handleSaveMealplan = () => {
        if(onUpdateMeaplanRecipe) {
            onUpdateMeaplanRecipe(mealplanRows, true)
        }
    }

    /**
     * Add Recipe to row handlers
     */
    const handleOnSelectSearchFocus = (rowIndex) => {
        setFocusedRowIndex(rowIndex)
        setFocusRecipeSelection(true)
    }

    const handleOnSelectItem = (option, rowIndex) => {
        setSelectedMealplanRecipe(option)

        if(option) {
            const recipe = {
                name: option?.name,
                value: option?.recipe_id || option?.value,
                image: option?.image
            }
            addMealplanRecipe(recipe, rowIndex)
        }
    }

    const dismissRecipeSelector = (e, rowIndex) => {
        if(rowIndex === focusedRowIndex && focusRecipeSelection) {
            setFocusedRowIndex(null)
            setFocusRecipeSelection(false)
        }
    }
    
    return (
        <div className="container mx-auto max-w-lg">
            {
                mealplanRows && mealplanRows?.length > 0 ? 
                    <ul>
                        {
                            mealplanRows.map((row, rowIndex) => {
                                const options = { 
                                    weekday: 'short', 
                                    year: 'numeric', 
                                    month: 'short', 
                                    day: 'numeric' 
                                }

                                return (
                                    <li key={rowIndex} className="text-an_green-dark">

                                        <div className="py-2 mt-8 mb-4 h-5">
                                            <hr className="border-an_green-dark" />
                                            <div className={`flex text-an_green-dark justify-between px-2 mt-[-11px] text-sm`}>
                                                {
                                                    row?.date && <span className="bg-an_white-light">{new Date(row?.date).toLocaleDateString("en-GB", options)}</span>   
                                                }
                                                <span className="bg-an_white-light">DAY: {row.day + 1}</span>                                            
                                            </div>
                                        </div>

                                        <ul className="p-4t">
                                            {
                                                row?.recipes && row?.recipes.length > 0 ? (
                                                    row.recipes.map((recipe, recipeIndex) => (
                                                            <li 
                                                                key={recipeIndex}
                                                                className={`odd:bg-an_white-dark hover:bg-an_white-darker1 cursor-pointer flex justify-between p-2`}
                                                            >
                                                                <span className="mr-2">{recipe.name}</span>

                                                                <button 
                                                                    onClick={() => handleRemoveMealplanRecipe(rowIndex, recipeIndex)}                                                    
                                                                    className="block ml-auto"
                                                                >
                                                                    <XCircleIcon className="w-6 h-6 text-an_orange" />
                                                                </button>
                                                            </li>
                                                        )
                                                    )
                                                ) : <li className="text-xs text-an_green-dark text-center py-4">. . .</li>    
                                            }
                                        </ul>
                                        
                                        <div className="flex justify-end items-center mt-2 mb-6 text-an_green-dark">
                                            <button
                                                onClick={() => { handleOnSelectSearchFocus(rowIndex) }}
                                                className="flex m-2 mt-0 text-xs items-center cursor-pointer"
                                            >
                                                <div className="pt-[2px]">add </div><PlusCircleIcon className="w-6 h-6 text-an_green ml-1" />
                                            </button>
                                        </div>


                                        {
                                            (focusRecipeSelection && focusedRowIndex === rowIndex) ?
                                                <div className="fixed bg-black bg-opacity-30 top-0 left-0 w-screen h-screen flex justify-center items-end z-50">
                                                    <div className="w-full fixed bottom-0 left-0">
                                                        {
                                                            (focusRecipeSelection && focusedRowIndex === rowIndex) &&
                                                                <button
                                                                    className="rounded z-50 w-8 h-8 mr-2 bg-an_green-dark text-an_white block ml-auto"
                                                                    onClick={(e) => { dismissRecipeSelector(e, rowIndex) }}

                                                                >
                                                                    <XIcon className="w-6 h-6 block mx-auto" />
                                                                </button>
                                                        }
                                                        <SearchSelectForm
                                                            showCreateCustom={true}
                                                            onCreateCustomItem={(index, option) => { handleCreateCustomItem(option, rowIndex) }}
                                                            showTabs={true} 
                                                            label="Search Recipes"
                                                            options={recipeOptions} 
                                                            selectedOptions={row.recipes}
                                                            onSelectItem={(index, option) => { handleOnSelectItem(option, rowIndex) }}
                                                            handleOnSelectSearchFocus={(newValue) => { handleOnSelectSearchFocus(rowIndex) }}
                                                            showOptions={focusRecipeSelection && focusedRowIndex === rowIndex}
                                                        />
                                                    </div>
                                                    
                                                </div> : ''     
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                : 'no plan yet ...'
            }

            { 
                showConfirmDelete && 
                    <ConfirmDialog 
                        title={`Delete Meaplan`}
                        message="This action cannot be undone, are you sure?" 
                        onConfirm={() => { 
                            deleteMealplan()
                            setShowConfirmDelete(false)
                        }} 
                        onCancel={() => { setShowConfirmDelete(false) }}
                    />
            }
        </div>
    )
}

export default MealplanCreator