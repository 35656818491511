/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'

/** lib */
import Loading from '../UI/Loading'
import { deleteUserProfile, updateUserPassword } from '../../services/user.service'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'

/** state */
import { getUserProfile, updateUserProfile } from '../../actions/user.actions'
import { logout } from '../../actions/auth.actions'

export default function UserSettingsForm() {
    const dispatch = useDispatch()

    const user = useSelector(state => state?.auth?.user?.user)
        
    // a9 utils
    const confirm = useConfirm()
    const toast = useToast()

    const [name, setName] = useState(user?.name)
    const [email, setEmail] = useState(user?.email)
    const [currentPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)    

    const [resetPasswordFormValid, setResetPasswordFormValid] = useState(false)
    const [userInfoFormValid, setUserInfoFormValid] = useState(false)
    const [unmaskPasswordState, setUnmaskPasswordState] = useState('password')

    const [formBasicErrors, setFormBasicErrors] = useState({})
    const [formPasswordErrors, setFormPasswordErrors] = useState({})

    useEffect(() => {
        const emailRegEx = /^[^@]+@[^@]+\.[^@]+$/
        const noChange = name === user?.name && email === user?.email
        
        if(name?.length > 3 && email.match(emailRegEx) && !noChange) {
            setUserInfoFormValid(true)
        } else {
            setUserInfoFormValid(false)
        }
    }, [name, email])

    useEffect(() => {
        if (password === confirmPassword && currentPassword?.length >= 5 && password.length >= 5) {
            setResetPasswordFormValid(true) 
        } else {
            setResetPasswordFormValid(false)
        }
    }, [currentPassword, password, confirmPassword])
    
    const updateInfoRequest = async () => {
        try {
            setSaving(true)
            const updateResponse = await dispatch(updateUserProfile({email: user.email, name}))
            setSaving(false)
            
            if(updateResponse?.success) {
                setFormBasicErrors({})
                await dispatch(getUserProfile())
                toast.open('User Updated.')
                setUserInfoFormValid(false)
            } else {
                if (updateResponse?.errors) 
                {
                    setFormBasicErrors(updateResponse.errors)
                } else {
                    toast.open('Unknown Error, Contact Administrator.')
                    setFormBasicErrors({})
                }
            }

        } catch (error) {
            setFormBasicErrors({})
            setSaving(false)
            console.error(error)
            toast.open('Unknown Error, Contact Administrator.')
        }
    }

    const handleupdateUserInfo = async () => {
        if(email !== user.email) {
            confirm.open(
                `Update Email Address?`,
                'You will lose access to social and sharing features until the new email address is verified. A verification email will be sent to the new address, the old address will also be notified',
                () => { updateInfoRequest() }
            )
        } else {
            updateInfoRequest()
        }
    }

    const handleResetPassword = () => {
        if (password === confirmPassword) {
            try {
                confirm.open(
                    `Change Password?`,
                    'Be sure to have copied your new password, you will be logged out.',
                    async () => {
                        setSaving(true)

                        const changePasswordResponse = await updateUserPassword({
                            current_password: currentPassword, 
                            new_password: password, 
                            confirm_password: confirmPassword
                        }) 

                        if(changePasswordResponse?.success) {
                            setFormPasswordErrors({})
                            
                            toast.open('Password Updated, logging out')
                            
                            setTimeout(() => {
                                setSaving(false)
                                dispatch(logout())
                            }, 250);
                        } else {
                            setSaving(false)
                            setFormPasswordErrors(changePasswordResponse?.errors ?? [])
                        }
                    }
                )                  
            } catch (error) {
                console.error(error)

                setSaving(false)
                setFormPasswordErrors({})
                toast.open('Unknown Error, Contact Administrator.')
            }   
        }
    }

    return (
        <>
            { loading && <Loading /> }

            {
                !loading && 
                    <form className="px-8">
                        <div className="py-2 mt-8 mb-4 h-5">
                            <hr className="border-an_green-dark" />
                            <div className="flex text-an_green-dark justify-between px-2 mt-[-14px]">
                                <span className="text-base block bg-an_white-light px-1 font-bold">update user information</span>
                            </div>
                        </div>

                        <div className="mb-1">
                            <label className="block text-an_green-dark text-base  mb-2" htmlFor="username">
                                update username
                            </label>
                            <input 
                                className={
                                    `${formBasicErrors?.email?.length > 0 ? 'border-an_orange-dark' : ''} bg-an_white-dark border rounded w-full text-base py-2 px-3 text-an_green-darker3`
                                }                                 
                                id="name"
                                value={name}
                                type="text" 
                                placeholder="Username"
                                onChange={e => setName(e.target.value)} 
                            />
                            <label className="block text-base  text-an_orange-dark mt-1">
                                { 
                                    formBasicErrors?.name?.length > 0 
                                        ? formBasicErrors?.name?.join(' ') 
                                        : <>&nbsp;</>
                                }
                            </label>
                        </div>

                        <div className="mb-1">
                            <label className="block text-base  text-an_green-dark mb-2" htmlFor="username">
                                update email
                            </label>
                            <input 
                                className={
                                    `${formBasicErrors?.email?.length > 0 ? 'border-an_orange-dark' : ''} bg-an_white-dark border rounded w-full text-base py-2 px-3 text-an_green-dark`
                                } 
                                id="email" 
                                type="text"
                                value={email} 
                                placeholder="email"
                                onChange={e => setEmail(e.target.value)} 
                            />
                            <label className="block text-base text-an_orange-dark mt-1">
                                    { 
                                        formBasicErrors?.email?.length > 0 
                                            ? formBasicErrors?.email?.join(' ') 
                                            : <>&nbsp;</>
                                    }
                            </label>
                        </div>

                        <div className="mb-1">
                            <button
                                onClick={() => { handleupdateUserInfo() }} 
                                disabled={!userInfoFormValid || saving} 
                                className={
                                    `${!userInfoFormValid ? 'opacity-60' : ''} w-36 relative mb-8 rounded bg-an_green-dark border-an_green-darker3 border p-1 px-2 text-base  text-an_white mx-auto block` 
                                }
                                type="button"
                            >
                                update { 
                                    saving &&
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 animate-spin absolute top-2 right-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg> 
                                }
                                
                            </button>
                        </div>

                        <div className="py-8 h-5">
                            <hr className="border-an_green-dark" />
                            <div className="flex text-an_green-dark justify-between px-2 mt-[-14px]">
                                <span className="text-base block bg-an_white-light px-1 font-bold">Update Password</span>
                                <div className="bg-an_white-light pt-[2px] px-1">
                                {
                                    unmaskPasswordState === 'password'
                                        ? 
                                            <button  type="button" onClick={() => setUnmaskPasswordState('text') }>
                                                <EyeIcon className="text-an_green-dark w-6 h-6"/>
                                            </button> 
                                        : <button className="text-an_green-darks" type="button" onClick={() => setUnmaskPasswordState('password') }>
                                            <EyeOffIcon className="text-an_green-dark w-6 h-6" />
                                        </button> 
                                }
                                </div>
                            </div>
                        </div>

                        <div className="mb-1">
                            <label className="block text-an_green-dark text-base mb-2" htmlFor="password">
                                Current Password
                            </label>
                            <input 
                                className={
                                    `${formPasswordErrors?.current_password?.length > 0 ? 'border-an_orange-dark' : ''} bg-an_white-dark border rounded w-full text-base py-2 px-3 text-an_green-dark`
                                } 
                                id="old_password"
                                value={currentPassword} 
                                type={unmaskPasswordState} 
                                placeholder=""
                                autoComplete="new-password" 
                                onChange={e => setOldPassword(e.target.value)} 
                            />
                            <label className="block text-base text-an_orange-dark mt-1">
                                { 
                                    formPasswordErrors?.current_password?.length > 0 
                                        ? formPasswordErrors?.current_password?.join(' ') 
                                        : <>&nbsp;</>
                                }
                            </label> 
                        </div>

                        <div className="mb-1">
                            <label className="block text-an_green-dark text-base  mb-2" htmlFor="password">
                                Update Password
                            </label>
                            <input 
                                className={
                                    `${formPasswordErrors?.new_password?.length > 0 ? 'border-an_orange-dark' : ''} bg-an_white-dark border rounded w-full text-base py-2 px-3 text-an_green-dark`
                                } 
                                id="password"
                                value={password} 
                                type={unmaskPasswordState} 
                                placeholder=""
                                autoComplete="new-password"
                                onChange={e => setPassword(e.target.value)} 
                            />
                            <label className="block text-base  text-an_orange-dark mt-1">
                                { 
                                    formPasswordErrors?.new_password?.length > 0 
                                        ? formPasswordErrors?.new_password?.join(' ') 
                                        : <>&nbsp;</>
                                }
                            </label> 
                        </div>

                        <div className="mb-1">
                            <label className="block text-an_green-dark text-base  mb-2" htmlFor="password">
                                Confirm Password
                            </label>
                            <input 
                                className={
                                    `${confirmPassword?.length > 7 && confirmPassword !==  password ? 'border-an_orange-dark' : ''} bg-an_white-dark border rounded w-full text-base py-2 px-3 text-an_green-dark`
                                }
                                id="confirm_password"
                                value={confirmPassword} 
                                type={unmaskPasswordState} 
                                placeholder=""
                                autoComplete="new-password"
                                onChange={e => setConfirmPassword(e.target.value)} 
                            />
                            <label className="block text-base  text-an_orange-dark mt-1">
                                { 
                                    confirmPassword?.length > 7 && confirmPassword !==  password 
                                        ? 'Password does not match.'
                                        : <>&nbsp;</>
                                }
                            </label> 
                        </div>
                        
                        <div>
                            <button
                                onClick={() => { handleResetPassword() }}
                                disabled={!resetPasswordFormValid} 
                                className={
                                    `${!resetPasswordFormValid ? 'opacity-60' : ''} w-[230px] relative mb-8 rounded bg-an_green-dark border-an_green-darker3 border p-1 px-2 text-base  text-an_white mx-auto block` 
                                }
                                type="button"
                            >
                                reset password { 
                                    saving &&
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 animate-spin absolute top-2 right-2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                }
                            </button>
                        </div>
                    </form>
            }
            
        </>
    )
}
