/** vendor */
import React, { useEffect, useState } from 'react'
import { PlusCircleIcon } from '@heroicons/react/solid'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

export default function SearchSelectForm({
    label = 'Search ...',
    buttonLabel = 'add',
    options = [], 
    selectedOptions = [],
    showTabs = false,
    showOptions = false,
    showCreateCustom = false,
    onCreateCustomItem = (option, index) => {}, 
    limit = 0,
    onSelectItem = () => { console.warn('SearchSelectForm: callback missing') },
    handleOnSelectSearchFocus  = () => {}
}) {
    const [allOptions, setAllOptions] = useState(options)
    const [filteredSortedOptions, setFilteredSortedOptions] = useState(options)
    const [currentTab, setCurrentTab] = useState(showTabs ? 'savedRecipes' : 'allOptions')
    const [currentSearch, setCurrentSearch] = useState('')

    const savedRecipes = useSelector(state => state?.user_data?.saved_recipes)
    const importedRecipes = useSelector(state => state?.user_data?.imported_recipes)
    const recipes = useSelector(state => state?.user_data?.user_recipes)

    /**
     * Hooks
     */
    useEffect(() => {
        if(options && options !== filteredSortedOptions) {
            setAllOptions(options)
            setFilteredSortedOptions(options)
        }
    }, [options, selectedOptions])

    /**
     * Handlers
     */
    const handleSearch = e => {
        if(e.target.value.length < 1) {
            let optionsLimit = [...options]

            setFilteredSortedOptions(allOptions)
            return
        }

        if(e.target.value.length > 2) {
            let filteredOptions = [...allOptions].filter(i => {
                const lowerName = i.name.toLowerCase().trim()
                const lowerValue = e.target.value.toLowerCase().trim()
                return lowerName === lowerValue || lowerName.includes(lowerValue)
            })
            
            setFilteredSortedOptions(filteredOptions)
        }
    }

    const handleSelection = (option, index) => {
        onSelectItem(index, option)
    }

    const handleCreateCustomItem = () => {
        onCreateCustomItem(null, {name: currentSearch, quantity: 1, image: null, value: 'CUSTOM_' + uuidv4()})        
    }

    return (
        <div className="w-full">
            <div className={`w-full ${showOptions ? 'p-2' : ''}`}>
                {
                    showOptions ?
                        <div className="bg-an_white overflow-hidden rounded-lg w-full">
                            <h2 className="w-full text-xl text-an_white text-center items-center p-4 bg-an_green-dark rounded-t mb-2">
                                add recipes
                            </h2>

                            {
                                showTabs &&
                                    <ul className="flex px-1 text-sm font-medium text-center border-b border-an_orange-dark overflow-hidden rounded-t-lg">
                                        <li className="w-full">
                                            <a href="#" 
                                                onClick={() => { setCurrentTab('savedRecipes') }}
                                                className={`${currentTab === 'savedRecipes' ? 'active bg-an_orange border-an_orange-dark text-an_white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                                            >My Recipes</a>
                                        </li>
                                        <li className="w-full">
                                            <a href="#" 
                                                onClick={() => { setCurrentTab('allOptions') }}
                                                className={`${currentTab === 'allOptions' ? 'active bg-an_orange border-an_orange-dark text-an_white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                                            >All Recipes</a>
                                        </li>
                                    </ul>
                            }

                            { 
                                showTabs && currentTab === 'savedRecipes' && 
                                    <ul className="overflow-y-auto text-sm h-[355px]">
                                        {
                                            savedRecipes && savedRecipes.length > 0 ?
                                                savedRecipes.map((option, index) => (
                                                    <li 
                                                        key={`select-search-option-${index}`} 
                                                        className={`odd:bg-an_white-dark hover:bg-an_white-darker1 py-1  pr-2`}
                                                    >
                                                        <div className="flex items-center pl-2 rounded">
                                                            <label 
                                                                className="w-full py-2 text-base text-an_green-dark"
                                                            >
                                                                {option.name}
                                                            </label>
                                                            <button
                                                                onClick={() => { handleSelection(option, index)} }
                                                                className="w-6 h-6 ml-auto mr-1 text-an_green"
                                                            >
                                                                <PlusCircleIcon className="text-an_green" />
                                                            </button>
                                                        </div>
                                                    </li> 
                                                )) : 
                                                <li>                        
                                                    <div className="flex items-center pl-2 rounded">
                                                        <label 
                                                            className="w-full py-2 text-sm font-medium text-black rounded"
                                                        >
                                                            No Options Found ...
                                                        </label>
                                                    </div>
                                                </li>                           
                                        } 
                                    </ul> 
                            }

                            { 
                                currentTab === 'allOptions' && 
                                    <ul className=" overflow-y-auto text-sm h-[300px]">
                                        {
                                            filteredSortedOptions && filteredSortedOptions.length > 0 ?
                                                filteredSortedOptions.map((option, index) => (
                                                    <li 
                                                        key={`select-search-option-${index}`} 
                                                        className={`odd:bg-an_white-dark hover:bg-an_white-darker1 py-1 pr-2`}
                                                    >
                                                        <div className="flex items-center pl-2">
                                                            <label 
                                                                className="w-full py-2 text-base text-an_green-dark"
                                                            >
                                                                {option.name}
                                                            </label>
                                                            <button
                                                                onClick={() => { handleSelection(option, index)} }
                                                                className="w-6 h-6 ml-auto mr-1 text-an_green"
                                                            >
                                                                <PlusCircleIcon className="text-an_green" />
                                                            </button>
                                                        </div>
                                                    </li> 
                                                )) : 
                                                <li>                        
                                                    <div className="flex items-center pl-2 rounded">
                                                        <label 
                                                            className="w-full py-6 text-base text-center text-an_green-dark"
                                                        >
                                                            ziltch.
                                                        </label>
                                                    </div>
                                                </li>                           
                                        }    
                                    </ul> 
                            }

                            { 
                                currentTab === 'allOptions' &&
                                    <div className="p-2 border-t border-an_green-dark bg-an_white-dark">
                                        <label htmlFor="input-group-search" className="sr-only">{label}</label>

                                        <div className="relative">
                                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg 
                                                    className="w-5 h-5 text-an_green" 
                                                    aria-hidden="true" 
                                                    fill="currentColor" 
                                                    viewBox="0 0 20 20" 
                                                    xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
                                                </svg>
                                            </div>
                                            <input 
                                                type="text" 
                                                className="block w-full p-2 pl-10 text-sm rounded" 
                                                placeholder={label} 
                                                onChange={(e) => {
                                                    setCurrentSearch(e.target.value) 
                                                    handleSearch(e) 
                                                }}
                                            />
                                            { 
                                                showCreateCustom && 
                                                    <button
                                                        disabled={filteredSortedOptions?.length > 0}
                                                        onClick={() => { handleCreateCustomItem() } }
                                                        className={`w-6 h-6 absolute inset-y-0 top-[6px] right-1 ${ filteredSortedOptions?.length > 0 && 'opacity-20' }`}
                                                    >
                                                        <PlusCircleIcon className="text-an_green" />
                                                    </button>
                                            }
                                        </div>
                                    </div> 
                            }                            
                        </div> :   
                        <div className="pb-4 flex justify-center">
                            <button 
                                className="bg-an_orange text-border-orange min-w-32 text-an_white rounded font-medium border border-an_orange-darker3 text-sm px-5 py-2"
                                onClick={(e) => { handleOnSelectSearchFocus(e) }}>{buttonLabel}</button>
                        </div>
                }
            </div>
        </div>
    )
}
