import React from 'react'
import { PlayIcon, PauseIcon } from '@heroicons/react/solid'
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

export default function RecipeStep({ step, ingredientList = [] }) {

  const stepImage = (step?.image) ? `${IMG_URL}/${step?.image}` : PLACEHOLDER_IMG
  
  const displayFormat = (value) => { 
    return value.split(':').map(i => i?.length < 2 ? '0' + i : i).join(':')
  } 

  return (
    <div className="border-t border-an_orange-darker1 pb-4">
        <div className="flex items-center ">
          <span className="block rounded-full m-2 text-white bg-an_orange-darker1 px-2">{step?.step_order}</span>
          <label className="lowercase pb-1 text-an_green-dark pt-1">{step?.name ?? `step ${step?.step_order}`}</label>
        </div>

        <div className="flex">
          <p className="pl-4 py-4 whitespace-pre-wrap text-base font-light">
            {step?.description}
          </p>
        </div>

        {
          ingredientList?.filter(i => step?.ingredients?.includes(i?.recipe_ingredient_id))?.length > 0 &&
            <div className="flex flex-wrap">          
              { 
                ingredientList?.filter(i => step?.ingredients?.includes(i?.recipe_ingredient_id)).map((i) => 
                    <span key={i.ingredient_id} className="text-black rounded-lg text-xs m-1">
                      {i?.quantity}{i?.measurement} {i?.treatment} {i?.ingredient?.name},
                    </span> 
                )
              }
            </div>
        }
    </div>
  ) 
}