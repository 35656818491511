/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { fetchRecipes, filterRecipes } from '../../../services/recipe.service'
import Loading from '../../UI/Loading'
import RecipeListItem from '../../Recipes/RecipeListItem'

/** state */
import { 
  getSavedRecipes,
} from '../../../actions/user.actions'


const StepTwoDiscover = () => {
  const dispatch = useDispatch()

  const [recipes, setRecipes] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchFilteredRecipes = async (filters) => {
    setLoading(true)

    await dispatch(getSavedRecipes())

    if(filters?.length > 0) {
      try {
        const res = await filterRecipes(1, '', filters)
        setRecipes(res?.data ?? [])
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    } else {
      try {
        const res = await fetchRecipes()
        setRecipes(res?.data ?? [])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }

    }
  }

  useEffect(() => {
    const filters = localStorage.getItem('filters') ?? '[]'

    try {
      const parsed = JSON.parse(filters) 
      fetchFilteredRecipes(parsed)
    } catch (error) {
      console.error(error)
    }
    
}, [])

  return (
    <div>
      <p className="text-black text-base text-center italic pb-4">
        Have a look at these meal ideas, tap the heart on a few to get started. 
      </p>

      <div className="w-full lg:w-2/3">
            {
                loading ? 
                    <div className="h-[60vh] relative pt-40">
                        <Loading />
                    </div> :
                    <section className="antialiased text-gray-600 h-[60vh] overflow-auto border-t border-b">
                        <div className="flex flex-col justify-center py-4">
                            <div className="">
                                <div>
                                    { 
                                        recipes 
                                            ? recipes.map(
                                                (r) =>  <RecipeListItem 
                                                            handleOnOpenAllergenDialog={() => {}} 
                                                            recipe={r} 
                                                            key={r.recipe_id} 
                                                        />
                                                )
                                            : <div className="text-center">No Recipes Found</div> 
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </section>
            }
      </div>

    </div>
  )
}

export default StepTwoDiscover