/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/** Lib */
import { useToast } from '../../hooks/useToast'

/** state */
import { 
    getShoppinglists, 
    deleteShoppinglist, 
    updateShoppinglist, 
    updateUserStore, 
    getUserStores, 
    getSharedWithMe 
} from '../../actions/user.actions'

const CompleteShoppingListDialog = ({
    showDialog = false,
    shoppinglistState = null,
    shoppinglistId,
    onConfirm = () => { },
    onCancel = () => { } 
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const toast = useToast()

    const shoppingLists = useSelector(state => state.user_data.shoppinglists || [])
    const foodstores = useSelector(state => state.user_data?.user_stores)
    const sharedWithMeShoppinglists = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserShoppinglist'))
    const sharedWithMeFoodStores = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserFoodStore'))
    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)

    const [saving, setSaving] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [deleteList, setDeleteList] = useState(false)
    const [storeComplete, setStoreComplete] = useState(true)
    const [selectedStoreId, setSelectedStoreId] = useState(null)
    const [moveIncomplete, setMoveIncomplete] = useState(false)
    const [selectedMoveListId, setSelectedMoveListId] = useState(null)
    const [moveItems, setMoveItems] = useState(false)
    const [storeItems, setStoreItems] = useState(false)
    const [shoppingListOptions, setShoppingListOptions] = useState([])
    const [storeOptions, setStoreOptions] = useState([])

    /**
     * Hooks
     */
    useEffect(() => {
        setIsOpen(showDialog)
        
        if(showDialog) {
            dispatch(getShoppinglists())
            dispatch(getUserStores())

            const moveItems = [...shoppinglistState].filter(i => !i.checked)
            const storeItems = [...shoppinglistState].filter(i => i.checked)

            setMoveItems(moveItems)
            setStoreItems(storeItems)
        }
    }, [showDialog])

    useEffect(() => {
        if(shoppingLists && shoppingLists.length > 0 && currentUsersEmail) {
            const shoppingListsCopy = [...shoppingLists].filter(i => i.user_shoppinglist_id !== shoppinglistId)
            const shoppingListOptions = shoppingListsCopy.map(i => { return { value: i.user_shoppinglist_id, name: i.name } })
            let editableSharedWithMeLists = []

            if(sharedWithMeShoppinglists && sharedWithMeShoppinglists.length > 0 && currentUsersEmail) {
                editableSharedWithMeLists = [...sharedWithMeShoppinglists].filter((item) => {
                    let canEdit = false

                    for(const user of item?.shared_with) {
                        if(user?.email === currentUsersEmail && user?.canEdit) {
                            canEdit = true
                            break
                        }
                    }

                    return canEdit
                }).map(i => { return { value: i.user_shoppinglist_id, name: i.name } })
            }
            const mergedOptions = [...shoppingListOptions, ...editableSharedWithMeLists]
            setShoppingListOptions(mergedOptions)
        }
    }, [shoppingLists, currentUsersEmail])

    useEffect(() => {
        if(foodstores && foodstores.length > 0) {
            const foodstoresCopy = [...foodstores]

            const foodStoreOptions = foodstoresCopy.map(i => { 
                if(i?.default) {
                    setSelectedStoreId(i.id)
                }
                return { value: i.id, name: i.name } 
            })

            let editableSharedWithMeStores = []

            if(sharedWithMeFoodStores && sharedWithMeFoodStores.length > 0 && currentUsersEmail) {
                editableSharedWithMeStores = [...sharedWithMeFoodStores].filter((item) => {
                    let canEdit = false

                    for(const user of item?.shared_with) {
                        if(user?.email === currentUsersEmail && user?.canEdit) {
                            canEdit = true
                            break
                        }
                    }

                    return canEdit
                }).map(i => {                 
                    return { value: i.id, name: i.name  } 
                })
            }
            const mergedOptions = [...foodStoreOptions, ...editableSharedWithMeStores]
            setStoreOptions(mergedOptions)
        }
    }, [foodstores, currentUsersEmail])

    /**
     * Handlers
     */
    const handleDeleteChange = (e) => {
        setDeleteList(!deleteList)
    }

    const handleStoreChange = (e) => {
        setStoreComplete(!storeComplete)
    }

    const handleMoveChange = (e) => {
        setMoveIncomplete(!moveIncomplete)
    }

    const handleMoveSelect = (e) => {
        setSelectedMoveListId(e.target.value)
    }

    const handleStoreSelect = (e) => {
        setSelectedStoreId(e.target.value)
    }

    const handleConfirm = async () => {
        setSaving(true)

        const setRequestError = (error) => {
            console.error(error)
            toast.open(`An Error has occurred :(`)
            setSaving(false)
        }

        // store completed items
        if(storeComplete) {

            const dateStoreItems = [...storeItems].map(i => {
                i.date_added = new Date().toISOString()    
                return i
            })

            let storeToUpdate = undefined 
            const foo = [...foodstores, ...sharedWithMeFoodStores]

            if(foo?.length < 2) {
                storeToUpdate = foodstores[0]
            } else {
                storeToUpdate = foodstores.find(store => store?.id === parseInt(selectedStoreId))
            }

            if(!storeToUpdate) {
                storeToUpdate = sharedWithMeFoodStores.find(store => store?.id === parseInt(selectedStoreId))
            }
            
            try {
                await dispatch(updateUserStore(selectedStoreId ?? storeToUpdate?.id, {items: [...storeToUpdate?.items, ...dateStoreItems]}))
            } catch (error) {
                setRequestError(error)
                throw new Error(error)
            }
        }

        // remove complete items (the moveItems array) if not deleting the list
        if(!deleteList && !moveIncomplete) {
            try {
                await dispatch(updateShoppinglist(shoppinglistId, {ingredients: moveItems}))
                setSaving(false)
            } catch (error) {
                setRequestError(error)
                throw new Error(error)
            }
        }

        if(moveItems.length < 1) {
            setMoveIncomplete(false)
        }

        if(moveIncomplete) {
            setDeleteList(true)

            try {
                // selectedMoveListId
                let listToUpdate = shoppingLists.find(slist => slist?.user_shoppinglist_id === parseInt(selectedMoveListId))

                if(!listToUpdate) {
                    listToUpdate = sharedWithMeShoppinglists.find(slist => slist?.user_shoppinglist_id === parseInt(selectedMoveListId))
                }

                await dispatch(updateShoppinglist(selectedMoveListId, {ingredients: [...listToUpdate?.ingredients, ...moveItems]}))

                if (!storeItems) {
                    await dispatch(updateShoppinglist(shoppinglistId, {ingredients: storeItems}))
                } else {
                    await dispatch(updateShoppinglist(shoppinglistId, {ingredients: []}))
                }
            } catch (error) {
                setRequestError(error)
                throw new Error(error)
            }
        }

        toast.open(`Lists and Stores Updated`)
        
        if(deleteList) {
            setTimeout(async () => {
                toast.open(`List Deleted`)

                try {
                    await dispatch(deleteShoppinglist(shoppinglistId))
                    setSaving(false)                    
                    navigate('/user-shopping-lists')
                } catch (error) {
                    setRequestError(error)
                    throw new Error(error)
                }
            }, 800);
        }

        if(!deleteList) {
            await dispatch(getShoppinglists())
            await dispatch(getSharedWithMe())
        }

        setSaving(false)
        onConfirm()
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'} items-center justify-center`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="bg-white p-8 fixed bottom-[70px] rounded-md shadow-md w-[95vw] z-50">
                <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Store Items</h2>

                <div className="flex min-w-full justify-center">
                    <div className="w-full">
                        <div className="flex py-2 items-center">
                            <input 
                                onChange={handleDeleteChange}
                                checked={deleteList}
                                disabled={saving} 
                                id="deleteList" 
                                type="checkbox" 
                                value="deleteList" 
                                className={`w-4 h-4 text-black bg-white border-black rounded`} 
                            />
                            <label 
                                htmlFor="deleteList" 
                                className={`ml-2 text-sm font-medium text-black`}
                            >Delete this list</label>
                        </div>

                        <div className="flex py-2 items-center">
                            <input 
                                onChange={handleStoreChange}
                                checked={storeComplete}
                                disabled={saving} 
                                id="storeComplete" 
                                type="checkbox" 
                                value="storeComplete" 
                                className="w-4 h-4 text-black bg-white border-black rounded" 
                            />
                            <label 
                                htmlFor="storeComplete" 
                                className="ml-2 text-sm font-medium text-black"
                            >Store checked items ({storeItems.length})</label>
                        </div>

                        {
                            storeComplete && storeOptions.length > 1 ?
                                <div className="pt-1 mt-2 border-t border-black">
                                    <label className="block py-2 text-xs w-full">Stores</label>
                                    <select
                                        value={selectedStoreId}
                                        disabled={saving} 
                                        id="sendToStore"
                                        onChange={(e) => { handleStoreSelect(e)} }
                                        className="border border-black text-black text-sm rounded block w-full p-2"
                                    >
                                        <option value="">Select ...</option>
                                        {
                                            storeOptions.map((option, i) => {
                                                return (
                                                    <option key={i} value={option.value}>{option.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> : ''
                        }

                        {
                            shoppingLists.length > 0 ?
                                <div className="flex py-2 items-center">
                                    <input
                                        disabled={saving} 
                                        onChange={handleMoveChange}
                                        checked={moveIncomplete} 
                                        id="moveIncomplete" 
                                        type="checkbox" 
                                        value="moveIncomplete" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="moveIncomplete" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Move unchecked items ({moveItems.length})</label>
                                </div> : ''
                        }
                        {
                            moveIncomplete && moveItems.length > 0 ?
                                <div className="pt-1 mt-2 border-t border-black">
                                    <label className="block py-2 text-xs w-full">Available Shopping Lists</label>
                                    <select
                                        value={selectedMoveListId}
                                        disabled={saving} 
                                        id="shoppinglistMove"
                                        onChange={(e) => { handleMoveSelect(e)} }
                                        className="border border-black text-black text-sm rounded block w-full p-2"
                                    >
                                        <option value="">Select ...</option>
                                        {
                                            shoppingListOptions.map((option, i) => {
                                                return (
                                                    <option key={i} value={option.value}>{option.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div> : ''
                        }
                    </div>
                </div>
            </div>

            <div className="flex justify-between mt-6 w-full fixed bottom-4 px-3">
                <button
                    disabled={saving}
                    className="px-4 py-2 text-sm bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
                    onClick={handleCancel}
                >
                    Cancel
                </button>

                <button
                    disabled={saving}
                    className="px-4 py-2 text-sm text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
                    onClick={handleConfirm}
                >
                    {
                        saving ? 
                        <div className="animate-pulse">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div> : 'Confirm'
                    }
                </button>
            </div>
        </div>
    )
}

export default CompleteShoppingListDialog
