/** vendor */
import React, { useEffect, useState } from 'react'
import { XIcon } from '@heroicons/react/outline'

/** Lib */
import { updateSharedWith } from '../../services/share.service'
import { useToast } from '../../hooks/useToast'

const ShareDialog = ({
    title = 'Share With',
    showDialog = false,
    shareWith = [],
    entityId,
    entityType,
    onUpdate = () => { },
    onCancel = () => { } 
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [saving, setSaving] = useState(false)
    const [isValidEmail, setIsValidEmail] = useState(false)
    const [email, setEmail] = useState('')
    const [shareWithList, setShareWithList] = useState([])
    const [unshareWithList, setUnshareWithList] = useState([])
    const toast = useToast()

    /**
     * Utils
     */
    const updateSharedEntityRequest = (shareList, unshareList) => {      
        setSaving(true)
        
        updateSharedWith({
            shared_with: shareList, 
            unshared_with: unshareList,
            entity_id: entityId, 
            type: entityType
        }).then((r) => {
            toast.open(`Sharing Updated`)
            setSaving(false)
            // console.warn('updateSharedWith', r)
        }).catch((e) => {
            toast.open(`Error: Failed to Share`)
            setSaving(false)
            // console.error('updateSharedWith', e)
        })
    }

    /**
     * Hooks
     */
    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    useEffect(() => {
        if (shareWith && shareWith.length > 0) {
            setShareWithList(shareWith)
        }
        else if(shareWithList?.length !== 0)
        {
            setShareWithList([])
        }
    }, [shareWith])    

    /**
     * Handlers
     */
    const handleChange = (e) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        setEmail(e.target.value)
        setIsValidEmail(regex.test(e.target.value))
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    const handleRemoveSharedUser = (user, index) => {
        const shareWithListCopy = [...shareWithList]
        const unshareWithListCopy = [...unshareWithList]
        
        if (unshareWithListCopy.indexOf(user) < 0)  {
            unshareWithListCopy.push(user)
            setUnshareWithList(unshareWithListCopy)
        }

        shareWithListCopy.splice(shareWithListCopy.indexOf(user), 1)

        setShareWithList(shareWithListCopy)
        updateSharedEntityRequest(shareWithListCopy, unshareWithListCopy)
        onUpdate(shareWithListCopy)
    }

    const handleGiveUserEditPermissionByIndex = (index) => {
        const shareWithListCopy = [...shareWithList]
        shareWithListCopy[index].canEdit = !shareWithListCopy[index].canEdit
        
        setShareWithList(shareWithListCopy)
        updateSharedEntityRequest(shareWithListCopy, unshareWithList)
        onUpdate(shareWithListCopy)
    }

    const handleConfirm = () => {
        if (!isValidEmail) return

        const shareWithListCopy = [...shareWithList]
        const unshareWithListCopy = [...unshareWithList]

        const newUser = {canEdit: false, email}

        shareWithListCopy.push(newUser)

        if (unshareWithListCopy.indexOf(newUser))  {
            unshareWithListCopy.splice(unshareWithListCopy.indexOf(newUser), 1)
            setUnshareWithList(unshareWithListCopy)
        }

        setShareWithList(shareWithListCopy)
        updateSharedEntityRequest(shareWithListCopy, unshareWithListCopy)
        onUpdate(shareWithListCopy)

        setEmail('')
    }

    return (
        <div
            className={`fixed bottom-0 left-0 w-full px-2 overflow-y-auto ${isOpen ? 'block' : 'hidden'
                }`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="bg-an_white mx-auto rounded w-full md:max-w-xl relative z-50">
                <h2 className="w-full text-xl text-an_white text-center items-center p-4 bg-an_green-dark rounded-t">
                    {title}
                </h2>

                {
                    shareWithList && shareWithList.length > 0 ?
                        <ul className="overflow-y-auto text-sm">
                            {
                                shareWithList.map((user, index) => (
                                    <li
                                        key={`shared-with-email-${index}`}
                                        className={`${index > 5 ? 'hidden' : ''} p-2 odd:bg-an_white-dark py-1`}
                                    >
                                        <div className="flex items-center justify-between pl-2 rounded">
                                            <div className="flex items-center">
                                                <button
                                                    onClick={() => { handleRemoveSharedUser(user, index) }}
                                                    className="w-6 h-6 ml-auto mr-1 p-1"
                                                >
                                                    <XIcon className="" />
                                                </button>

                                                <label
                                                    className="w-full py-2 text-base font-normals text-an_green-dark"
                                                >
                                                    {user.email}
                                                </label>
                                            </div>
                                            <div className="items-center pr-2 mt-[-8px]">
                                                    <label 
                                                        htmlFor="deleteList" 
                                                        className="text-[8px] font-medium text-black block text-center"
                                                    >Edit</label>
                                                    <input 
                                                        onChange={() => { handleGiveUserEditPermissionByIndex(index) }}
                                                        disabled={saving}
                                                        checked={user?.canEdit} 
                                                        id="adjustIngredidents" 
                                                        type="checkbox" 
                                                        value="adjustIngredidents" 
                                                        className={`${saving && 'opacity-60'}`} 
                                                    />
                                                    
                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul> : ''
                }

                <div className="flex min-w-full justify-center">
                    <div className="w-full">
                        <div className="w-full">
                            <div className="relative flex p-2 pt-4 pb-0">
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => {handleChange(e) }}
                                    className={`${email.length > 5 && !isValidEmail ? 'border-an_orange-dark' : ''} rounded w-full`}
                                    placeholder="email address"
                                />
                            </div>
                            <label className="block text-sm text-an_orange-dark p-2 pt-1">
                                { 
                                    email.length > 5 && !isValidEmail
                                        ? 'Invalid email address' 
                                        : <>&nbsp;</>
                                }
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex justify-center w-full py-3 md:max-w-xl mx-auto relative">
                <button
                    disabled={saving}
                    className="px-5 py-2 rounded w-36 text-an_green-dark bg-an_white mr-1"
                    onClick={handleCancel}
                >
                close
                </button>

                <button
                    onClick={() => { handleConfirm() }}
                    disabled={saving || !isValidEmail}
                    className={`${saving || !isValidEmail ? 'opacity-80' : ''} px-5 ml-1 py-2 relative rounded w-36 border border-an_orange-darker3 text-an_white bg-an_orange text-border-orange`}
                >
                <span>share</span> 
                    {
                        saving && 
                            <div className="animate-pulse absolute right-2 top-2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </div> 
                    }
                </button>
            </div>
        </div>
    )
}

export default ShareDialog
