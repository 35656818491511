import React, { useState } from 'react'
import MealplanPanel from '../../components/Dashboard/MealplanPanel'
import ShoppingPanel from '../../components/Dashboard/ShoppingPanel'
import StoreNotifications from '../../components/Dashboard/StoreNotifications'
import SocialNotifications from '../../components/Dashboard/SocialNotifications'
import { Link } from 'react-router-dom'
import { createWorker } from 'tesseract.js'
import ScanResultDialog from '../../components/Dialogs/ScanResultDialog'
import Loading from '../../components/UI/Loading'
import { Card } from '../../components/UI/Card'

export default function UserDashboard() {

  const [showScanResult, setShowScanResult] = useState(false)
  const [scanResult, setScanResult] = useState('')
  const [loading, setLoading] = useState(false)
  const [scanning, setScanning] = useState(false)

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]
        const newUrl = URL.createObjectURL(file)
        scanReciept(newUrl)
      }
    }
  }

  const scanReciept = async (newUrl) => {
    setScanning(true)

    const worker = await createWorker('eng')
    const ret = await worker.recognize(newUrl)
    
    await worker.terminate()

    setScanResult(ret)

    setScanning(false)
    setShowScanResult(true)
  }

  return (
    <div className="pt-16 w-full overflow-hidden">
      {
        scanning &&
          <Card>
            <div className="pt-40">
              <Loading title="Processing Scan" />
            </div>
          </Card>
      }

      {
        !scanning &&
          <div 
            className="flex flex-col overflow-hidden" 
            style={{ height: 'calc(100vh - 80px)' }}
          >
            <div className="w-full mx-auto sm:flex sm:justify-center">
              <div className="mb-2 sm:max-w-xl sm:w-1/2">
                <MealplanPanel />
              </div>
              <div className="mb-2 sm:max-w-xl sm:w-1/2">
                <ShoppingPanel />
              </div>
            </div>
            {/* <div className="mb-2"><StoreNotifications /></div>
            <div className="mb-2"><SocialNotifications /></div> */}

            <div className="md:max-w-4xl md:mx-auto flex-grow w-screen py-4 flex justify-center items-end px-4 flex-shrink-0">
              <Link to="/recipes" className="w-1/3 mr-1 block">
                <button
                  type="button" className="text-white block w-full bg-black rounded font-medium border border-black text-sm px-5 py-2"
                >Recipes</button>
              </Link>
              
              <Link to="/user-stores" className="w-1/3 mx-1 block">
                <button
                  type="button" className="text-white block w-full bg-black rounded font-medium border border-black text-sm px-5 py-2"
                >Stores</button>
              </Link>
              
              <div className="w-1/3 ml-1">
                <input
                  className="hidden" 
                  accept="image/*" 
                  id="capture-receipt" 
                  type="file" 
                  capture="environment"
                  title="Scan"
                  onChange={(e) => handleCapture(e.target)}
                />

                <label
                  htmlFor="capture-receipt"
                  type="button" 
                  className="text-white w-full block text-center bg-black rounded font-medium border border-black text-sm px-5 py-2"
                >Scan</label>
              </div>
            </div>
          </div>
      }

      <ScanResultDialog
        showDialog={showScanResult}
        scanResult={scanResult}
        onCancel={() => { 
          setShowScanResult(false)
        }}
        onConfirm={() => { console.warn('todo') }} 
      />
    </div>
  )
}