/** vendor */
import React, { useState } from 'react'

const StepFiveFeatureSummary = () => {
  return (
    <div>
      <p className="text-black text-base text-center italic py-4">you're all set, but whats next?</p>

      <ul className="text-black text-sm pt-4 list-disc py-8 pl-8 ">
        <li className="py-1">go shopping</li>
        <li className="py-1">do a quick stock take</li>
        <li className="py-1">iscover more recipes</li>
        <li className="py-1">import an old favorites</li>
        <li className="py-1">update the mealplan</li>
        <li className="py-1">check out the food diary</li>
        <li className="py-1">share your own culinary creations</li>
      </ul> 
  </div>
  )
}

export default StepFiveFeatureSummary