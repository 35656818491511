/** vendor */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'

/** state */
import { 
  getUserMealplan, 
  getSharedWithMe 
} from '../../actions/user.actions'

/** components */
import MealplanIngredientSummaryDialog from '../../components/Dialogs/MealplanIngredientSummaryDialog'
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'

export default function FoodDiary() {
  // vendor utils
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // a9 utils
  const confirm = useConfirm()
  const toast = useToast()

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [showFoodDiaryEntryDialog, setShowFoodDiaryEntryDialog] = useState(false)
  const [showIngredientSummaryDialog, setShowIngredientSummaryDialog] = useState(false)

  // ui states
  const [loading, setLoading] = useState(false)  

  // mealplan data
  const mealplan = useSelector(state => state.current_mealplan)
  const [mealsCompleted, setMealsCompleted] = useState([])

  /**
   * Hooks
   */
  useEffect(() => {
    setLoading(false)
  }, [])

  /**
   * Handlers
   */
  const handleShowOptions = () => {
    setShowOptions(true)
  }

  const handleOnShowIngredientSummary = () => {
    setShowOptions(false)
    setShowIngredientSummaryDialog(true)
  }

  const handleOnAction = (action, id) => {
    // if (action === 'delete')  {
      
    //   confirm.open(
    //     `Delete ${mealplan?.mealplan?.name}?`,
    //     'This action cannot be undone',
    //     () => {
    //       setLoading(true)

    //       dispatch(deleteMealplan(id)).then(() => {
    //         toast.open('Meal Plan Deleted.') 
    //         navigate(`/user-meal-plans`) 
    //       }).catch(() => {
    //         setLoading(false)
    //       })
    //     }
    //   )
    // }

    if (action === 'check') {
        handleOnShowIngredientSummary()
    }

  }


  return (
    <div className="pt-16 min-w-full">
        <Card title={mealplan?.mealplan?.name || ''}
          status={
            <div className="flex">
              <button
                className="text-xs h-6 w-6 text-black rounded-full"
                onClick={ (e) => { handleShowOptions() } }
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                </svg>
              </button>
            </div>
          }
        >
            <div className="overflow-y-auto">
                {
                    loading && <div className="h-[60vh] relative pt-40"><Loading /></div>
                }

                {
                    !loading &&
                        <div className="h-[30vh]">
                            <div className="text-center opacity-60 mt-8">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 mx-auto block">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                            </svg>
                            <p>work in progress<br />food diary.</p>
                            </div>
                        </div>
                }
            </div> 
        </Card>
 
        <div className={`fixed bottom-0 left-0 w-screen px-2 pb-3 flex items-center justify-center ${mealsCompleted.length < 1 && 'opacity-50'}`}>         
            <div>
            <button
                disabled={true}
                onClick={() => { setShowFoodDiaryEntryDialog(true) }}
                type="button" 
                className={`rounded font-medium border border-black text-sm px-5 py-2
                text-white bg-black
                `}
            >
                add entry
            </button>
            </div>
        </div> 
        
        <MealplanIngredientSummaryDialog 
          showDialog={showIngredientSummaryDialog} 
          title="Ingredient Summary" 
          mealplanId={mealplan?.mealplan?.user_mealplan_id}
          onClose={() => { setShowIngredientSummaryDialog(false) }}
        />

        {
            showFoodDiaryEntryDialog && <></>
        }
    </div>
  )
}