/** vendor */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'

/** lib */
import { Card } from '../../components/UI/Card'

/** components */
import RegisterForm from '../../components/Forms/RegisterForm'
import Logo from '../../components/UI/Logo'

export default function Register() {
  const navigate = useNavigate()

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-an_green-dark">
      <div className="flex flex-col justify-around h-screen w-screen overflow-auto">
        
      <div className={`flex flex-1 items-center justify-center w-full mx-auto flex-col`}>
        <Logo />

        <div>
          <h1 className="block text-white text-2xl font-light text-center w-full mb-4">Register</h1>
          <hr className="border-an_green-light" />
          <div className="max-w-xl mx-auto">
            <RegisterForm />
          </div>
        </div>
      </div>

      </div>
      
      <button 
        className="h-6 text-an_white absolute bottom-2 left-1 flex text-xs items-center"
        onClick={() => navigate('/home') }
      >
        <ChevronLeftIcon className="w-6" /> back
      </button>
    </div>
  )
}