/** vendor */
import React, { useState } from 'react'

/** lib */
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'

/** components */
import SavedRecipeList from '../../components/Recipes/SavedRecipeList'
import ImportedRecipeList from '../../components/Recipes/ImportedRecipeList'
import CustomRecipeList from '../../components/Recipes/CustomRecipeList'

export default function UserRecipes() {
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('saved')

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  return (
    <div className="pt-12 md:max-w-4xl w-full">
        <Card title="My Recipes" autoHeight="true">
            {
                loading && <div className="h-[60vh] relative pt-40"><Loading /></div>
            }

            {
                !loading &&
                    <div 
                        className="w-full flex relative flex-col items-center pb-0" 
                        style={{ height: 'calc(100vh - 108px)' }}
                    >
                        <ul className="w-full border-b-2 border-an_orange-dark flex justify-center flex-wrap text-sm font-medium bg-an_white-dark text-center sticky p-2 pb-0 left-0 top-0">
                            <li className="w-1/3 text-center px-1">
                                <a 
                                href="#saved" 
                                aria-current="page"
                                onClick={handleToggleTab}  
                                className={`${tab === 'saved' 
                                        ? 'active bg-an_orange text-border-orange border border-an_orange-dark border-b-0 text-an_white'
                                        : 'text-an_orange-dark'} block py-1 px-2 rounded-t  border-transparent border border-b-0`
                                    }
                                >
                                    saved
                                </a>
                            </li>
                            <li className="w-1/3 text-center px-1">
                                <a 
                                href="#custom"
                                onClick={handleToggleTab} 
                                className={`${tab === 'custom' 
                                        ? 'active bg-an_orange text-border-orange border border-an_orange-dark border-b-0 text-an_white'
                                        : 'text-an_orange-dark'} block py-1 px-2 rounded-t  border-transparent border border-b-0`
                                    }>
                                custom
                                </a>
                            </li>
                            <li className="w-1/3 text-center px-1">
                                <a 
                                href="#imported"
                                onClick={handleToggleTab}
                                className={`${tab === 'imported' 
                                    ? 'active bg-an_orange text-border-orange border border-an_orange-dark border-b-0 text-an_white'
                                    : 'text-an_orange-dark'} block py-1 px-2 rounded-t  border-transparent border border-b-0`
                                } 
                                >
                                imported
                                </a>
                            </li>
                        </ul>
            
                        { tab === 'saved' && <SavedRecipeList /> }
                        { tab === 'custom' && <CustomRecipeList /> }
                        { tab === 'imported' && <ImportedRecipeList /> }
                    </div>
            }
        </Card>
    </div>
  )
}