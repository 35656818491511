/** vendor */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { XCircleIcon } from '@heroicons/react/outline'

/** lib */
import { useDebounce } from '../../hooks/useDebounce'
import Loading from '../../components/UI/Loading'
import { Card } from '../../components/UI/Card'

/** state */
import { fetchShoppinglist, createShoppinglist, updateShoppinglist, deleteShoppinglist } from '../../services/user.service'

/** components */
import SearchSelectForm from '../../components/Forms/SearchSelectForm'
import { getFormOptions } from '../../actions/form.actions'
import SelectUnitForm from '../../components/Forms/SelectUnitForm'

const ShoppingListCreateEdit = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    const ingredientOptions = useSelector(state => state.form_options?.ingredient_options || [])

    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [isSaved, setIsSaved] = useState(false)
    const [shoppinglistName, setShoppinglistName] = useState('')
    const [shoppinglistItems, setShoppinglistItems] = useState([])
    const [focusSearch, setFocusSearch] = useState(false)
    const [listId, setListId] = useState(null)

    /**
     * Hooks
     */
    useEffect(() => {
        setLoading(true)

        dispatch(getFormOptions()).then(() => { 
            if(window.location.href.includes('edit')) {
                setLoading(true)
    
                fetchShoppinglist(location.pathname.split('/')[3]).then((r) => {
                    const mapQuantities = r.shopping_list.ingredients.map((i) => {
                        if (!i?.quantity) { i['quantity'] = 1 }
                        return i
                    })

                    setListId(r.shopping_list.user_shoppinglist_id)
                    setShoppinglistName(r.shopping_list.name)
                    setShoppinglistItems(mapQuantities)
                    setIsSaved(true)
                    setLoading(false)
                }).catch(() => {
                    setLoading(false)
                })
            } else {
                setIsSaved(false)
                setLoading(false)
            }

        }).catch(() => {
            setLoading(false)
        })
    }, [])

    useDebounce(() => {
        if (isSaved) {
            handleUpdateShoppingList(shoppinglistName, shoppinglistItems)
        } 
    }, [shoppinglistItems], 1500)

    /**
     * Handlers
     */
    const handleOnselectUnit = (newValue, item) => {
        const shoppinglistItemsCopy = [...shoppinglistItems]
        const indexToUpdate = shoppinglistItemsCopy.findIndex(i => item?.value === i?.value && item.date_added === i.date_added)
        
        shoppinglistItemsCopy[indexToUpdate]['unit'] = newValue
        setShoppinglistItems(shoppinglistItemsCopy)
      }

    const handleOnUpdateQuantity = (newValue, item) => {
        const shoppinglistItemsCopy = [...shoppinglistItems]
        const indexToUpdate = shoppinglistItemsCopy.findIndex(i => item?.value === i?.value && item.date_added === i.date_added)
    
        shoppinglistItemsCopy[indexToUpdate].quantity = newValue
        setShoppinglistItems(shoppinglistItemsCopy)
    }

    const handleOnSearchFocus = () => {
        setFocusSearch(true)
    }

    const handleOnSelectItem = (index, option) => {
        if (saving) { return }

        const shoppinglistItemsCopy = shoppinglistItems.map(item => {
            if (item.value !== null && item.value === option.value) {
                return { ...item, quantity: item.quantity + item.default_quantity }
            } else if (item.value === null && item.name === option.name) {
                return { ...item, quantity: item.default_quantity }
            }
            return item
        })
    
        if (!shoppinglistItemsCopy.some(item => 
            (item.value !== null && item.value === option.value) ||
            (item.value === null && item.name === option.name)
        )) {
            shoppinglistItemsCopy.push({ quantity: 1, ...option })
        }
    
        setShoppinglistItems(shoppinglistItemsCopy)
    }

    const handleCreateCustomItem = (index, option) => {
        const shoppinglistItemsCopy = [...shoppinglistItems, {quantity: 1, ...option}]
        setShoppinglistItems(shoppinglistItemsCopy)       
    }

    const handleRemoveItem = (item) => {
        if (saving) { return }

        const shoppinglistItemsCopy = [...shoppinglistItems]        
        setShoppinglistItems(shoppinglistItemsCopy.filter(i => i.value !== item.value))
    }

    const handleCreateShoppingList = () => {
        setSaving(true)

        createShoppinglist({
            name: shoppinglistName,
            ingredients: []
        })
        .then((r) => {
            setListId(r?.user_shoppinglist_id)
            setSaving(false)
            setIsSaved(true)
        }).catch(() => {
            setSaving(false)
        })
    }

    const handleUpdateShoppingList = (name, items) => {
        setSaving(true)

        updateShoppinglist(listId, {
            name: name,
            ingredients: items
        })
        .then((r) => {
          setSaving(false)
          setIsSaved(true)
        }).catch(() => {
          setSaving(false)
        })
    }

    const handleDeleteShoppingList = () => {
        // @todo
        setSaving(true)

        deleteShoppinglist(listId)
        .then((r) => {
          setSaving(false)
          setIsSaved(true)
        }).catch(() => {
          setSaving(false)
        })
    }
    
    return (
        <div className="pt-12 w-full md:max-w-4xl mx-auto">
            <Card 
                title={isSaved ? shoppinglistName : 'Create a Shopping List'}
                status={
                    saving && ( 
                      <div className="animate-pulse">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                      </div> 
                    )
                  }
            >
                { 
                    loading &&
                        <div className="h-[60vh] relative pt-40"><Loading /></div>
                }

                {
                    !loading &&
                        <div className="h-[65vh]">
                            {
                                isSaved &&
                                    <>
                                        <ul>
                                            { 
                                                (shoppinglistItems && shoppinglistItems.length > 0) ?
                                                    shoppinglistItems.map((item, i) => {
                                                        return (
                                                            <li className="flex justify-between p-2 odd:bg-an_white-dark hover:bg-an_white-darker1 cursor-pointer" key={i}>
                                                                <div className="flex">
                                                                    <div className="w-12">
                                                                        <input 
                                                                            className="border py-1 px-1 border-gray-400 text-black text-center text-xs w-full rounded-t" 
                                                                            id="quantity" 
                                                                            type="number"
                                                                            min="1"
                                                                            disabled={false}
                                                                            onChange={(e) => { handleOnUpdateQuantity(e.target.value, item) } }
                                                                            value={item?.quantity || item?.default_quantity} 
                                                                        />
                                                                        <SelectUnitForm 
                                                                            onSelectUnit={(newValue) => {handleOnselectUnit(newValue, item)}} 
                                                                            defaultUnit={item?.default_unit} 
                                                                            userUnit={item?.unit}
                                                                            canEdit={true} 
                                                                        />
                                                                    </div>
                                                                    
                                                                    <div className="text-an_green-dark text-lg py-2 pl-2 flex-grow">
                                                                        <label className="block">{item.name}</label>
                                                                    </div> 
                                                                </div>

                                                                <div className="flex justify-end items-center">
                                                                    <button
                                                                        className="text-xs w-6 ml-1 text-black"
                                                                        onClick={() => { handleRemoveItem(item) }}
                                                                    ><XCircleIcon className="w-6 h-6" /></button>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) :      
                                                    <li className="text-center">
                                                        <p className="text-center py-4 text-gray-400">No Items Found</p>
                                                    </li> 
                                            }
                                        </ul>         
                                    </> 
                            }
                        </div>
                }


            </Card>
            
            {
                isSaved ?
                    <div className={`${focusSearch ? 'h-screen bg-black' : ''} fixed bg-opacity-30 bottom-0 left-0 w-screen flex justify-center items-end z-40`}>
                        <div className="w-screen">
                            {
                                focusSearch ?                                            
                                    <button 
                                        className="rounded-full w-8 h-8 mb-4 mr-4 bg-white text-black block ml-auto"
                                        onClick={(e) => { setFocusSearch(false) }}
                                    >&times;</button>
                                    : <></>
                            }

                            <div className="w-full">
                                <SearchSelectForm
                                    label="Search Ingredients"
                                    options={ingredientOptions}
                                    selectedOptions={shoppinglistItems}  
                                    onSelectItem={(newValue, option) => { handleOnSelectItem(newValue, option) }}
                                    handleOnSelectSearchFocus={handleOnSearchFocus}
                                    showOptions={focusSearch}
                                    showCreateCustom={true}
                                    onCreateCustomItem={handleCreateCustomItem}
                                />
                            </div> 
                        </div> 
                    </div>  : 
                    <div className="fixed bottom-0 shadow left-0 w-screen p-4 justify-center">
                        <div className="py-2">
                            <button 
                                type="button" 
                                className={`${(!shoppinglistName || saving) && 'opacity-50'} text-white flex items-center bg-black font-medium rounded-lg text-sm px-5 py-2.5 uppercase mx-auto`}
                                disabled={!shoppinglistName || saving}
                                onClick={(newValue) => { handleCreateShoppingList(newValue) }}
                                >
                                    <span>Create</span>
                                    {
                                        saving && (
                                            <div className="animate-pulse ml-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                </svg>
                                            </div>
                                        )

                                    } 
                                </button>
                        </div>      
                    </div>
            }

        </div>
    )
}

export default ShoppingListCreateEdit
