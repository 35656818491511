/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/** lib */
import Loading from '../../components/UI/Loading'
import { Card } from '../../components/UI/Card'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'
import { removeSelfFromSharedList } from '../../services/share.service'

/** state */
import { deleteShoppinglist, getSharedWithMe, getShoppinglists } from '../../actions/user.actions'

/** components */
import ShoppinglistOptions from '../../components/Shoppinglist/ShoppinglistOptions'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import ShoppingListListItem from '../../components/Shoppinglist/ShoppingListListItem'
import CreateShoppingListDialog from '../../components/Dialogs/CreateShoppingListDialog'

export default function ShoppingListList() {
  // vendor utils
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [showCreate, setShowCreate] = useState(false)

  // ui states
  const [loading, setLoading] = useState()
  // const [saving, setSaving] = useState()
  const [tab, setTab] = useState('mylists')

  // a9 utils
  const confirm = useConfirm()
  const toast = useToast()

  // lists
  const shoppingLists = useSelector(state => state.user_data.shoppinglists)
  const sharedWithMeLists = useSelector(state => state.user_data.shared_with_me.filter(m => m.type === 'UserShoppinglist'))

  // shared with me list permissions
  const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
  
  const [shoppinglistContext, setShoppinglistContext] = useState({
    user_shoppinglist_id: null,
    title: '',
    isSharedStore: false,
    canEditStore: true,
    shared_with: []
  })

  /**
   * Hooks
   */
  useEffect(() => {
    setLoading(true)

    dispatch(getShoppinglists()).then(() => {
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }, [])

  /**
   * Handlers
   */
  const handleShowOptions = (shoppinglist) => {
    const isSharedList = tab !== 'mylists' 
    let canEdit = !isSharedList

    if(isSharedList) {
      for(const user of shoppinglist?.shared_with) {
        if(user?.email === currentUsersEmail && user?.canEdit) {
            canEdit = true
            break
        }
      }
    }

    setShoppinglistContext({
      user_shoppinglist_id: shoppinglist.user_shoppinglist_id,
      title: shoppinglist.name,
      shared_with: shoppinglist.shared_with,
      isSharedList: isSharedList,
      canEditList: canEdit
    })

    setShowOptions(true)
  }

  const handleOnAction = (action, id) => {
    if (action === 'delete') {
      confirm.open(
        `Delete ${shoppinglistContext?.title}?`,
        'This action cannot be undone',
        () => {
          setLoading(true)

          dispatch(deleteShoppinglist(id)).then(() => {
            dispatch(getShoppinglists()).then(() => {
              setLoading(false)
              setShowOptions(false)
              toast.open('list deleted.')
            })
          }).catch(() => {
            setLoading(false)
          })
        }
      )
    }

    if (action === 'remove') {
      confirm.open(
        `remove ${shoppinglistContext?.title} from shared lists?`,
        'this action cannot be undone.',
        () => {
          setLoading(true)

          removeSelfFromSharedList({entity_id: id, entity_type: 'UserShoppinglist'}).then(() => {
            dispatch(getSharedWithMe()).then(() => {
              setLoading(false)
              setShowOptions(false)
              toast.open(`list ${shoppinglistContext?.title}, removed.`)
            })
          }).catch(() => {
            setLoading(false)
          })

        })
    }

    if (action === 'share') {
      setShowOptions(false)
      setShowShare(true)
    }

    if (action === 'shop') {
      navigate(`/user-shopping-lists/${id}?viewMode=shopmode`) 
    }

    if (action === 'edit') {
      navigate(`/user-shopping-lists/edit/${id}`)  
    }

    if (action === 'clipboard') {
      const list = [...shoppingLists, ...sharedWithMeLists].find(list => list?.user_shoppinglist_id === id) 

      const ingredients = [...list?.ingredients].map(i => {
        return `${i?.name}`
      })
    
      const text = ingredients.join('\r\n')

      if ('clipboard' in navigator) {
          navigator.clipboard.writeText(text)
          toast.open(`Copied to Clipboard`)
          return
      } 

      const element = document.createElement("textarea")

      element.value = text
      document.body.appendChild(element)
      element.select();
      document.execCommand("copy")
      document.body.removeChild(element)
      
      toast.open(`Copied to Clipboard`)
      return
    } 
  }

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  return (
    <div className="pt-12 md:max-w-4xl w-full">
      <Card title="Shopping Lists">
        {
          loading &&
            <div className="h-[60vh] relative pt-40"><Loading /></div>
        }

        {
          !loading &&
            <div className="w-full flex flex-col items-center">
              <ul className="w-full pt-2 border-b-2 border-an_orange-dark flex justify-center flex-wrap text-sm font-medium text-center">
                <li className="w-1/2 px-1">
                  <a
                    href="#mylists"
                    aria-current="page"
                    onClick={handleToggleTab}
                    className={`${tab === 'mylists' ? 'active bg-an_orange text-white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                  >
                    my lists
                  </a>
                </li>
                <li className="w-1/2 px-1">
                  <a
                    href="#shared"
                    onClick={handleToggleTab}
                    className={`${tab === 'shared' ? 'active bg-an_orange text-white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                  >
                    shared with me
                  </a>
                </li>
              </ul>

              {
                tab === 'mylists' &&
                  <ul className="min-w-full m-h-[72vh]">
                    {
                      shoppingLists && shoppingLists?.length > 0
                        ? shoppingLists.map((l, i) => {
                            return (
                              <li key={i} className={`odd:bg-an_white-dark hover:bg-an_white-darker1 cursor-pointer`}>
                                <ShoppingListListItem list={l} index={i} onShowOptions={handleShowOptions} />
                              </li>) 
                            })
                        : <li className="text-center">
                            <p className="text-center py-40 text-an_green-dark">...</p>
                          </li>
                    }
                  </ul>
              }

              {
                tab === 'shared' && 
                  <ul className="min-w-full h-[72vh] overflow-y-auto">
                    {
                      sharedWithMeLists && sharedWithMeLists?.length > 0 ?
                        sharedWithMeLists.map((l, i) => 
                          <li key={i} className={`odd:bg-an_white-dark hover:bg-an_white-darker1 cursor-pointer`}>
                            <ShoppingListListItem list={l} index={i} onShowOptions={handleShowOptions} />
                          </li>
                        ) :
                          <li className="text-center">
                            <p className="text-center py-4 text-gray-400">...</p>
                          </li>
                    }
                  </ul>
              }

            </div>        
        }
      </Card>

      {
        !showCreate && !showShare &&
          <div className="fixed bottom-0 left-0 w-screen py-4 flex justify-center">
            <button
              onClick={() => { setShowCreate(true) }}
              type="button" 
              className="bg-an_orange text-border-orange w-32 text-an_white rounded font-medium border border-an_orange-darker3 text-sm px-5 py-2"
            >Create</button>
          </div>
      }

      <ShoppinglistOptions
        listId={shoppinglistContext?.user_shoppinglist_id}
        title={shoppinglistContext?.title}
        showDialog={showOptions}
        onAction={handleOnAction}
        onCancel={() => { setShowOptions(false) }}
        isSharedWithUser={shoppinglistContext?.isSharedList}
        canEdit={shoppinglistContext?.canEditList}
      />

      <ShareDialog
        entityId={shoppinglistContext?.user_shoppinglist_id}
        entityType="UserShoppinglist"
        showDialog={showShare}
        title={`Share ${shoppinglistContext?.title}`}
        shareWith={shoppinglistContext?.shared_with}
        onCancel={ () => { setShowShare(false) } } 
      />

      <CreateShoppingListDialog 
        showDialog={showCreate}
        onCancel={() => { setShowCreate(false) }}
        onCreate={(newShoppingListId) => { 
          setShowCreate(false)
          navigate(`/user-shopping-lists/edit/${newShoppingListId}`) 
        }}
      />
    </div>
  )
}
