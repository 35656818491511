/** vendor */
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/solid'

export default function ShoppingListListItem({list, index, onShowOptions}) {
    const navigate = useNavigate()

    if (!list) {
        return <></>
    }
    
    return (    
        <div 
            className="flex justify-between items-center p-2"
            onClick={ () => navigate(`/user-shopping-lists/${list.user_shoppinglist_id}?view=readonly`)}    
        >
            <div className="text-an_green-dark">
                <small className="block leading-none text-xs font-light">updated: {new Date(list?.updated_at).toLocaleDateString().padStart(10, '0')}</small>
                <div>
                    <label className={`block mt-1 text-lg ${list?.owner_name ? 'leading-none' : ''}`}>{list?.name}</label>
                </div>
                { 
                    list?.owner_name && 
                        <div className="text-xs font-light">owner: {list?.owner_name }</div> 
                }
            </div>
            <div className="text-center w-14">
                <button
                    className="text-xs h-6 w-6 ml-auto border border-an_green-dark text-an_green-dark bg-an_white-dark hover:bg-an_green-dark hover:text-an_white-dark active:bg-an_green-dark active:text-an_white-dark rounded-full"
                    onClick={ 
                    (e) => {
                        e.stopPropagation() 
                        onShowOptions(list) 
                    } 
                    }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-auto">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>

                </button>
                <small className="text-an_green-dark leading-none block" style={{ fontSize: 10 }}>{list?.ingredients?.length} items</small>
            </div>
        </div>        
    )
}