/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** state */
import { getImportedRecipe } from '../../actions/user.actions'
import { Card } from '../../components/UI/Card'

import RecipeStep from './RecipeStep'

export default function ImportedRecipeDetail({ recipe }) {
    return (
        <>
            {
                recipe &&
                    <div className="min-w-full flex overflow-y-auto justify-center card-no-footer px-2">
                        <div className="flex flex-col w-full">
                            <div className="w-full md:flex">
                                <div className="w-full flex md:flex-col">
                                    <div>
                                        <img 
                                            src={recipe?.image?.url ?? recipe?.image[0]}
                                            className="h-auto rounded-lg w-32 md:w-44 my-2 mx-auto"
                                        />
                                    </div>

                                    <div className="flex flex-col w-full pt-2">
                                        <small className="text-left text-black text-xs p-2 block">
                                            original source<br /><a href={recipe?.url ?? recipe?.['@id']} className="underline">{ recipe?.url }</a>
                                        </small>
                                        <small className="px-2 text-black text-xs block w-full">Serves: {recipe?.recipeYield}</small>
                                    
                                        <div className="my-2 px-2 font-light">
                                            { recipe.description }
                                        </div>
                                    </div>
                                </div>

                                <div className="md:pl-4 md:pt-2 md:overflow-auto">
                                    <div>
                                        {
                                            recipe?.recipeInstructions.map((step, index) => {
                                                const s =  {
                                                    name: step?.name,
                                                    description: step?.text, 
                                                    step_order: index + 1,
                                                    image: ''
                                                }

                                                return (
                                                    <RecipeStep 
                                                        step={s} 
                                                        key={index} 
                                                    />
                                                )
                                            })
                                        }
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>         
            }
        </>        
    )
}
