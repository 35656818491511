/** vendor */
import React, { useState, useEffect } from 'react'

const StepOnePreferences = () => {
  const [selectedFilters, setSelectedFilters] = useState([])

  const isSelectedPreference = (val) => {
    const index = selectedFilters?.findIndex(f => f?.value === val)
    return (index > -1)
  }

  useEffect(() => {
    const filters = localStorage.getItem('filters') ?? '[]'

    if(filters && JSON.parse(filters)?.length > 0) {
      setSelectedFilters(JSON.parse(filters))
    }
  }, [])

  const handleSelectPreference = (e) => {
    const selectFiltersCopy = [...selectedFilters]

    if (e?.target?.checked) {
      selectFiltersCopy?.push({
        value: e?.target?.value,
        name: e?.target.value,
        type: 'preference'
      })
    } else {
      const index = selectFiltersCopy?.findIndex(f => f?.value === e?.target?.value)
      selectFiltersCopy?.splice(index, 1)
    }

    localStorage.setItem('filters', JSON.stringify(selectFiltersCopy))
    setSelectedFilters(selectFiltersCopy)
  }


  return (
    <div>
      <p className="text-black text-base text-center italic pb-4">
        Start by setting a few basic preferences. 
        after you're all done here, a range of comprehensive filters can be added 
        from the discover section filters and in your account settings.
      </p>

      <div className="w-[150px] mx-auto">
        <div className="flex py-4 items-center">
            <input 
                onChange={(e) => { handleSelectPreference(e) }}
                checked={isSelectedPreference('vegetarian')} 
                id="Vegetarian" 
                type="checkbox" 
                value="vegetarian" 
                className="w-4 h-4 text-black bg-white border-black rounded" 
            />
            <label 
                htmlFor="Vegetarian" 
                className="ml-2 text-base font-medium text-black"
            >vegetarian</label>
        </div>

        <div className="flex py-4 items-center">
            <input 
                onChange={(e) => { handleSelectPreference(e) }}
                checked={isSelectedPreference('vegan')} 
                id="Vegan" 
                type="checkbox" 
                value="vegan" 
                className="w-4 h-4 text-black bg-white border-black rounded" 
            />
            <label 
                htmlFor="Vegan" 
                className="ml-2 text-base font-medium text-black"
            >vegan</label>
        </div>

        <div className="flex py-4 items-center">
            <input 
                onChange={(e) => { handleSelectPreference(e) }}
                checked={isSelectedPreference('gluten_free')}  
                id="GlutenFree" 
                type="checkbox" 
                value="gluten_free" 
                className="w-4 h-4 text-black bg-white border-black rounded" 
            />
            <label 
                htmlFor="GlutenFree" 
                className="ml-2 text-base font-medium text-black"
            >gluten free</label>
        </div>

        <div className="flex py-4 items-center">
            <input 
                onChange={(e) => { handleSelectPreference(e) }}
                checked={isSelectedPreference('sugar_free')}
                id="SugarFree" 
                type="checkbox" 
                value="sugar_free" 
                className="w-4 h-4 text-black bg-white border-black rounded" 
            />
            <label 
                htmlFor="SugarFree" 
                className="ml-2 text-base font-medium text-black"
            >sugar free</label>
        </div>

        <div className="flex py-4 items-center">
            <input 
                onChange={(e) => { handleSelectPreference(e) }}
                checked={isSelectedPreference('dairy_free')}
                id="DairyFree" 
                type="checkbox" 
                value="dairy_free" 
                className="w-4 h-4 text-black bg-white border-black rounded" 
            />
            <label 
                htmlFor="DairyFree" 
                className="ml-2 text-base font-medium text-black"
            >dairy free</label>
        </div>
      </div>
    </div>
  )
}

export default StepOnePreferences