export default function Loading({ title = '' }) {
    
    return (
        <div className="flex flex-col justify-center items-center">
            { title ? <small className="text-an_green-dark uppercase py-2 block text-center">{title}</small> : '' }
            <div className="flex justify-center items-center">
                <div className="h-2.5 w-2.5 bg-an_green-light rounded-full animate-pulse animate-bounce"></div>
                <div className="h-2.5 w-2.5 bg-an_green-light rounded-full mx-4 animate-pulse animate-bounce200"></div>
                <div className="h-2.5 w-2.5 bg-an_green-light rounded-full animate-pulse animate-bounce400"></div>
            </div>
        </div>


    )
}