/** vendor */
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** components */
import SaveRecipeForm from '../Forms/SaveRecipeForm'
import TagIcons from '../UI/TagIcons'

export default function RecipeListItem({
    recipe,
    handleOnOpenAllergenDialog = (allergens) => {}
}) {
    const navigate = useNavigate()

    const handleClick = (recipe_id) => {
        navigate(`/recipes/${recipe_id}`)
    }

    const allergens = recipe.recipe_ingredients.filter((i) => {
        return (i.ingredient.allergen)
    })

    const vegan = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.vegan)
    }) === undefined

    const vegetarian = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.vegetarian)
    }) === undefined    

    const sugarFree = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.sugar_free)
    }) === undefined

    const glutenFree = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.gluten_free)
    }) === undefined

    const dairyFree = recipe.recipe_ingredients.find((i) => {
        return (!i.ingredient.dairy_free)
    }) === undefined

    const recipeImage = (recipe.image) ? `${IMG_URL}/${recipe.image}` : PLACEHOLDER_IMG

    return (
        <div className="w-full p-1 relative">
            <div 
                className="flex cursor-pointer" 
                onClick={() => { 
                    handleClick(recipe?.recipe_id) 
                }}
            >
                <div className="flex-shrink-0">
                    <small className="text-left text-an_green block text-[10px]">
                        {recipe.category?.name}
                    </small> 

                    {
                        recipeImage && 
                            <div className="my-1">
                                <img src={recipeImage} className="w-14 md:w-40 rounded border border-an_green-dark"/>
                            </div>
                    }

                    <small className="text-left text-an_green-dark block text-[10px]">
                        Serves: {recipe.serves}
                    </small>
                    <SaveRecipeForm recipe_id={recipe.recipe_id} />
                </div>

                <div className="flex-grow flex flex-col pl-4">
                    <div className="pb-10 pt-4">          
                        <div className="text-an_green-dark text-xl">
                            { recipe.name }
                        </div>
                        <div className="text-left flex-wrap flex-shrink-1 py-1 text-an_green-dark">
                            {recipe.description}
                        </div>  
                    </div>

                    <div className="absolute bottom-2 right-0 flex w-full justify-end items-center">
                        <div>
                            <TagIcons
                                    fodMapWarning={false}
                                    allergenWarning={allergens.length > 0}
                                    allergenDialog={
                                        (e) => { 
                                            e.stopPropagation()
                                            handleOnOpenAllergenDialog(allergens)
                                        }
                                    }
                                    vegetarian={vegetarian}
                                    vegan={vegan}
                                    glutenFree={glutenFree}
                                    sugarFree={sugarFree}
                                    dairyFree={dairyFree}
                            />           
                        </div>                        
                    </div>

                </div>
            </div>
        </div>
    )
}