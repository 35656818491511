/** vendor */
import React, { useState } from 'react'

/** components */
import StepZeroWelcome from './TutorialDialogs/StepZeroWelcome'
import StepOnePreferences from './TutorialDialogs/StepOnePreferences'
import StepTwoDiscover from './TutorialDialogs/StepTwoDiscover'
import StepFourBetaNotice from './TutorialDialogs/StepFourBetaNotice'
import StepThreeShoppingLists from './TutorialDialogs/StepThreeShoppingLists'
import StepFiveFeatureSummary from './TutorialDialogs/StepFiveFeatureSummary'

const FirstLoginDialog = ({ onConfirm, onCancel }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [step, setStep] = useState(0)

  const handleComplete = () => {
    setIsOpen(false)
    onCancel()
  }

  
  const handleBack = () => {
    setStep(step-1)
    onConfirm()
  }

  const handleContinue = () => {
    setStep(step+1)
    onConfirm()
  }

  const handleCancel = () => {
    setIsOpen(false)
    onCancel()
  }

  return (
    <div
      className={`fixed z-40 inset-0 p-2 overflow-y-auto ${
        isOpen ? 'flex' : 'hidden'
      } items-center justify-center`}
    >
      <div className="fixed inset-0 bg-black opacity-30"></div>
      <div className="flex flex-col bg-white p-8 rounded-md shadow-md w-96 h-[96vh] max-w-full z-50">
        <h2 className="text-xl font-semibold mb-4 text-center">
          { step === 0 && 'Welcome to aisle nine' }
          { step === 1 && 'Basic preferences' }    
          { step === 2 && 'Discover recipes' }
          { step === 3 && 'Time to shop' }
          { step === 4 && 'Thank you for participating' }
          { step === 5 && 'Finale' }  
        </h2>
        <div className="flex-grow">
          { step === 0 && <StepZeroWelcome /> }
          { step === 1 && <StepOnePreferences /> }
          { step === 2 && <StepTwoDiscover /> }
          { step === 3 && <StepThreeShoppingLists /> }
          { step === 4 && <StepFourBetaNotice /> }
          { step === 5 && <StepFiveFeatureSummary /> }
        </div>
        <div className="flex flex-col">
          { step === 0 &&
            <>
              <button
                className="mb-2 px-4 py-2 text-white bg-black border"
                onClick={handleContinue}
              >
                Show me how this works
              </button>
              <button
                className="px-4 py-2 text-black" 
                onClick={handleCancel}
              >
                I can figure it out myself
              </button>
            </>
          }

          <div className="flex justify-between">
            { 
              step > 0 &&
                <button
                  className="px-4 py-2 text-black" 
                  onClick={handleBack}
                >
                  Back
                </button> 
            }

            { 
              step > 0 && step < 5 &&
                <button
                  className="px-4 py-2 text-black" 
                  onClick={handleContinue}
                >
                  Continue
                </button>
            
            }

            { 
              step === 5 &&
                <button
                  className="px-4 py-2 text-black" 
                  onClick={handleComplete}
                >
                  Close
                </button>
            }
          </div>

        </div>
      </div>
    </div>
  )
}

export default FirstLoginDialog
