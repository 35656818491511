import React, { useState } from 'react'

/** components */
import FilterDialog from '../../components/Dialogs/FilterDialog'
import { Card } from '../../components/UI/Card'
import UserSettingsForm from '../../components/Forms/UserSettingsForm'
import { useToast } from '../../hooks/useToast'

export default function UserPreferences() {

  // a9 utils
  const toast = useToast()

  const [showFilters, setShowFilters] = useState(false)
  const [tab, setTab] = useState('account')
  const toggleFilters = e => { setShowFilters(!showFilters) }

  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  const handleOnEnableTutorial = () => {
    localStorage.removeItem('tutorial_enabled')
    toast.open('tutorial enabled.')
}

  return (
    <div className="pt-12 w-full md:max-w-4xl mx-auto">
      <Card 
        title="Account Settings" 
      >
        <ul className="py-1 pb-0 w-full flex justify-center flex-wrap text-sm font-medium text-center border-b-2 border-an_orange-dark">
            <li className="w-1/3 text-center px-1">
                <a 
                  href="#account" 
                  aria-current="page"
                  onClick={handleToggleTab}  
                  className={`${tab === 'account' ? 'active bg-an_orange border border-an_orange-dark text-an_white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                >
                    account
                </a>
            </li>
            <li className="w-1/3 text-center px-1">
                <a 
                  href="#profile"
                  onClick={handleToggleTab} 
                  className={`${tab === 'profile' ? 'active bg-an_orange border border-an_orange-dark text-an_white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                >
                  profile
                </a>
            </li>
            <li className="w-1/3 text-center px-1">
                <a 
                  href="#beta"
                  onClick={handleToggleTab} 
                  className={`${tab === 'beta' ? 'active bg-an_orange border border-an_orange-dark text-an_white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                >
                  beta tools
                </a>
            </li>
        </ul>
        { tab === 'account' && <UserSettingsForm /> }
        { 
          tab === 'profile' && 
            <div className="text-center mt-8 text-an_green-dark">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-20 h-20 mx-auto block">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z" />
                </svg>
                <p>Coming Soon.</p>
            </div>
        }
        { 
          tab === 'beta' && 
            <div>
              <div className="p-8">
                  <button
                      onClick={() => { handleOnEnableTutorial() }}
                      className={
                          `rounded bg-an_green border-black border p-1 px-2 text-base text-an_white mt-2 block` 
                      }
                      type="button"
                  >
                      reset tutorials
                  </button>
              </div>
            </div> 
        }
      </Card>

      <div className="flex items-center justify-center pb-4 fixed bottom-0 left-0 w-full">
          <button 
              className="rounded p-2 w-56 border border-an_orange-darker3 text-an_white bg-an_orange text-border-orange" 
              type="button"
              onClick={() => { setShowFilters(true) }}
              >
              Set filter preferences
          </button>
      </div>

      <FilterDialog
        context="save" 
        showDialog={showFilters} 
        onUpdate={() => {}} 
        onCancel={toggleFilters}
      />

    </div>
  );
}