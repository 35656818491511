/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchIcon, FilterIcon } from '@heroicons/react/solid'
import { FilterIcon as FilterIconOutline } from '@heroicons/react/outline'

/** lib */
import Loading from '../UI/Loading'
import Pagination from '../UI/Pagination'

/** state */
import { getRecipes, searchRecipes, filterRecipes } from '../../actions/recipe.actions'

/** components */
import RecipeListItem from './RecipeListItem'
import SearchForm from '../../components/Forms/SearchForm'
import FilterDialog from '../Dialogs/FilterDialog'
import AllergenDialog from '../Dialogs/AllergenDialog'

export default function RecipeList() {
    const dispatch = useDispatch()

    const recipes = useSelector(state => state?.recipes?.recipes?.data)
    const pagination = useSelector(state => state?.recipes?.recipes)

    const [currentSearchTerm, setCurrentSearchTerm] = useState('')
    const [allergenDialogContent, setAllergenDialogContent] = useState([])
    const [appliedFilters, setAppliedFilters] = useState(undefined)

    const [loading, setLoading] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [showAllergens, setShowAllergens] = useState(false)
    
    const toggleFilters = e => { 
        setShowFilters(!showFilters) 
        setShowSearch(false)
    }

    const toggleSearch = e => { 
        setShowSearch(!showSearch)
        
        setTimeout(() => { 
            if (!showSearch) {
                document.getElementById('recipeSearchInput').focus() 
            } 
        }, 100)
    }

    const toggleAllergens = e => { 
        setShowAllergens(!showAllergens) 
    }

    const fetchFilteredRecipes = (filters) => {
        setShowFilters(false)
        setLoading(true)

        if(filters?.length > 0) {
            setCurrentSearchTerm(currentSearchTerm)
            setAppliedFilters(filters)

            const searchTerm = currentSearchTerm.length > 2 ? currentSearchTerm : ''
            dispatch(filterRecipes(1, searchTerm, filters)).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

            return
        }  
        
        if (currentSearchTerm.length > 2 && !loading) {
            dispatch(searchRecipes(1, currentSearchTerm)).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

            return
        }

        dispatch(getRecipes()).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

        return
    }

    const onCancelFilters = () => {
        setAppliedFilters(undefined)
        setShowFilters(!showFilters)
        fetchFilteredRecipes([]) 
    }

    useEffect(() => {
        setLoading(true)

        const filters = localStorage.getItem('filters')
    
        if(filters && JSON.parse(filters)?.length > 0) {
            setAppliedFilters(JSON.parse(filters))
            fetchFilteredRecipes(JSON.parse(filters))
        } else {
            dispatch(getRecipes()).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })
        }
    }, [])

    const handleOnOpenAllergenDialog = (allergens) => {
        setAllergenDialogContent(allergens)
        setShowAllergens(true)
    }
    
    const handleOnFilter = (filters) => {
        fetchFilteredRecipes(filters)
    }

    const handleOnPaginate = (pageToRequest) => {
        setLoading(true)

        if(appliedFilters?.length > 0) {
            const searchTerm = currentSearchTerm.length > 2 ? currentSearchTerm : ''

            dispatch(filterRecipes(pageToRequest, searchTerm, appliedFilters)).then(() => {
                setCurrentSearchTerm(currentSearchTerm)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

            return
        } 

        if (currentSearchTerm?.length > 2) {
            dispatch(searchRecipes(pageToRequest, currentSearchTerm)).then(() => {
                setCurrentSearchTerm(currentSearchTerm)
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })  
    
            return       
        }  
        
        dispatch(getRecipes(pageToRequest)).then(() => {
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

        return
    }

    const handleOnSearchRecipes = (searchString) => {
        if(searchString === currentSearchTerm) {
            return
        }
        
        setCurrentSearchTerm(searchString)
        setLoading(true)

        if(appliedFilters?.length > 0 && searchString.length > 2 && !loading) {
            dispatch(filterRecipes(1, searchString, appliedFilters)).then(() => {    
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

            return
        }

        if (searchString.length > 2 && !loading) {
            dispatch(searchRecipes(1, searchString)).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

            return
        }

        if (searchString.length < 1 && !loading)
        {
            dispatch(getRecipes(1)).then(() => {
                setLoading(false)
            }).catch(() => {
                setLoading(false)
            })

            return
        }
    }

    return (
        <div className="w-full">
            {
                loading  
                    ?   <div className="h-[60vh] relative pt-40">
                            <Loading />
                        </div>
                    :   <section className="antialiased">
                            <div className="flex flex-col justify-center">
                                <div>
                                    <div >
                                        { 
                                            recipes 
                                                ? recipes.map(
                                                    (r) =>  <div className="w-full pt-2 px-2 cursor-pointer odd:bg-an_white-dark hover:bg-an_white-darker1">
                                                        <RecipeListItem 
                                                                handleOnOpenAllergenDialog={handleOnOpenAllergenDialog} 
                                                                recipe={r} 
                                                                key={r.recipe_id} 
                                                            />
                                                        </div>
                                                    )
                                                : <div className="text-center">No Recipes Found</div> 
                                        }
                                    </div>
                                    
                                </div>
                            </div>
                        </section>
            }

            <div className="fixed flex h-[42px] align-middle items-center bottom-4 left-0 p-2 w-full justify-center">
                <div className="relative mr-8">
                    <SearchIcon className="h-8 w-8 inline-flex text-an_white-darker3 flex-shrink-0" onClick={toggleSearch} />
                    { 
                        currentSearchTerm?.length > 0 && 
                            <div className="rounded-full w-2 h-2 top-0 right-0 absolute border border-white bg-green-600"></div> 
                    }
                </div>
                         
                { 
                    showSearch 
                        && <div className="px-2 w-full">
                            <SearchForm 
                                onSearch={handleOnSearchRecipes} 
                                currentSearchString={currentSearchTerm}  
                            />
                        </div>
                }

                { 
                    recipes && pagination?.last_page > 1 &&
                        <Pagination
                            onPaginate={handleOnPaginate}
                            pagination={pagination} 
                        />
                }

                <div className="relative ml-8">
                    <div className="relative">
                        { 
                            showFilters || appliedFilters?.length > 0 
                                ? <FilterIcon className="h-6 w-6 inline-flex text-an_white-darker3" onClick={toggleFilters} /> 
                                : <FilterIconOutline className="h-6 w-6 inline-flex text-an_white-darker3" onClick={toggleFilters} />
                        }
                        { 
                            appliedFilters?.length > 0 && 
                                <div className="rounded-full w-4 h-4 text-[10px] text-center text-white top-[-3px] left-[-4px] absolute border border-white bg-green-600">
                                    {appliedFilters?.length}
                                </div> 
                        }
                    </div>
                    
                    <FilterDialog 
                        showDialog={showFilters} 
                        onUpdate={handleOnFilter} 
                        onCancel={onCancelFilters}
                    />
                </div>
                <AllergenDialog 
                    showDialog={showAllergens} 
                    onCancel={toggleAllergens} 
                    allergens={allergenDialogContent}  
                />
            </div>
        </div>
    )
}
