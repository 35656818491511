/** vendor */
import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** components */
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'

export default function IngredientsDropdown({ ingredients, type = 'AISLENINE' }) {
    const [showIngredients, setShowIngredients] = useState(false)

    const formatQuantity = (value) => {
        const stringValue = value.toString()

        if (stringValue.includes('.')) {
          const [integerPart, decimalPart] = stringValue.split('.')
      
          if (/^0+$/.test(decimalPart)) {
            return parseInt(integerPart, 10)
          }
        }
      
        return value    
    }

    return(
        <div className={`px-2 py-3 w-screen relative`}>
            <div className={`${ showIngredients ? 'fixed h-screen w-screen bg-black opacity-50 top-0 left-0 z-40' : 'hidden' }`}></div>
            <div 
                style={{ bottom: '100%' }}
                className={`absolute left-0 w-screen px-2 ${showIngredients ? 'z-50' : 'hidden'}`}>
                <div className={`rounded overflow-hidden text-an_green-dark border border-an_green-dark bg-an_white md:max-w-lg mx-auto`}>
                    { showIngredients &&
                        <ul className="max-h-[80vh] overflow-y-auto">
                            {
                                ingredients.map((i, index) =>
                                {
                                    const ingredientImage = (i?.image) ? `${IMG_URL}/${i.image}` : PLACEHOLDER_IMG

                                    return (
                                        <li className={`p-2 ${index > 0 ? 'border-t border-an_green' : ''} min-w-full cursor-pointer odd:bg-an_white-dark hover:bg-an_white-darker1`} key={i?.recipe_ingredient_id || i?.value}>
                                            <div className="flex items-center">                                                     
                                                <div className="flex-1 min-w-0">
                                                    <div className={`truncate text-an_green-dark flex w-full justify-between items-center`}>
                                                        <div className="whitespace-normal">{i?.quantity && formatQuantity(i?.quantity)} {i?.measurement} {i?.treatment} {i?.ingredient?.name || i?.name}</div>
                                                        { 
                                                            i?.ingredient?.allergen  
                                                                ? <div className="text-orange-400 opacity-40 text-xs h-6 flex items-center text-center">
                                                                    <ExclamationCircleIcon className="h-6 w-6" />
                                                                </div> : <></> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul> 
                    }                    
                </div>
            </div>

            <div 
                className={`w-full md:max-w-lg mx-auto p-2 relative rounded flex justify-between border border-an_green-dark text-an_green-dark bg-an_white-light ${showIngredients ? 'z-50' : ''}`}
                onClick={ () => { setShowIngredients(!showIngredients) }}
            >
                <label className="block text-an_green-dark">
                    Ingredients
                </label>
                <button>
                    { 
                        showIngredients 
                            ? <ChevronUpIcon className="h-6 w-6" /> 
                            : <ChevronDownIcon className="h-6 w-6" /> 
                    }
                </button>
            </div>
        </div>
    )
}