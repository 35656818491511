import SaveRecipeForm from '../Forms/SaveRecipeForm'
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import TagIcons from '../UI/TagIcons'

export default function RecipeDetailItem({recipe, canSave = true}) {
    const vegan = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.vegan)
    }) === 'undefined'

    const vegetarian = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.vegetarian)
    }) === 'undefined'    

    const sugarFree = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.diabetes_ok)
    }) === 'undefined'

    const glutenFree = recipe?.recipe_ingredients?.find((i) => {
        return (!i?.ingredient?.gluten_free)
    }) === 'undefined'

    const recipeImage = (recipe?.image) ? `${IMG_URL}/${recipe.image}` : PLACEHOLDER_IMG

    return (
        <div className="w-full flex md:flex-col">
            <div>
                <img 
                    src={recipeImage}
                    className="h-auto rounded-lg w-32 md:w-44 my-2 mx-auto border border-an_green-dark"
                />

                <small className="px-2 text-black text-xs block w-full">Serves: {recipe?.serves}</small>
                <small className="text-left text-black text-xs p-2 block">
                    <a href={'/recipes?category=@todo'} className="underline">{recipe?.category?.name}</a>
                </small>
            </div>

            <div className="flex-grow">
                <div className="my-2 px-2 font-light">
                    { recipe.description }
                </div>

                <div className="flex-1 border-an_green md:border-t flex justify-end md:justify-between w-full">
                    <div className="w-2/3 flex justify-end md:justify-start">
                        <TagIcons 
                            fodMapWarning={false}
                            allergenWarning={recipe?.tags?.includes('allergen')}
                            vegetarian={recipe?.tags?.includes('vegetarian')}
                            vegan={recipe?.tags?.includes('vegan')}
                            dairyFree={recipe?.tags?.includes('dairy_free')}
                            glutenFree={recipe?.tags?.includes('gluten_free')}
                            sugarFree={recipe?.tags?.includes('sugar_free')}
                        />
                    </div>

                    <div className="mt-2 flex-grow-0 md:w-1/3 pl-2 flex justify-end">
                        { canSave && <SaveRecipeForm recipe_id={recipe?.recipe_id} /> }
                    </div>
                </div>
            </div>
        </div>
    )
}