/** vendor */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { XCircleIcon } from '@heroicons/react/outline'

/** lib */
import { PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

export default function ImportedRecipeListItem({
    importedRecipe,
    onHandleRemove
}) {
    const navigate = useNavigate()
    const [recipeJson, setRecipeJson] = useState(null)

    const handleClick = (recipe_id) => {
        navigate(`/imported-recipes/${recipe_id}`)
    }

    /**
     * Hooks
     */
    useEffect(() => {
        try {
            setRecipeJson(JSON.parse(importedRecipe.data))
        } catch (error) {
            console.warn(error)
        }
    }, [importedRecipe])

    const recipeImage = !recipeJson?.import_failed ? recipeJson?.image?.url ?? recipeJson?.image?.[0] : PLACEHOLDER_IMG

    const handleRemoveImportedRecipe = (importedRecipe) => {
        onHandleRemove(importedRecipe)
    }

    return (
        <>
        {
            importedRecipe && recipeJson && 
                <div className="w-full py-1 px-2">
                    <div className="flex w-full justify-between items-center pb-2">
                        <small className="text-left text-an_green-dark block">{!recipeJson?.import_failed ? recipeJson?.recipeCuisine : ''}</small>
                        <button
                            className="text-xs w-5 ml-1 text-an_green-dark"
                            onClick={() => { handleRemoveImportedRecipe(importedRecipe) }}
                        >
                            <XCircleIcon className="w-5 h-5 text-an_green-dark" />
                        </button>
                    </div>
                    
                    <div className="flex-1 flex cursor-pointer" 
                        onClick={() => { handleClick(importedRecipe?.import_recipe_id) }}
                    >    
                        {
                            importedRecipe?.import_failed ? 
                                <div>
                                    <div className="underline text-an_green-dark">
                                        <a 
                                            className="break-all"
                                            href={importedRecipe.url} 
                                            target="_blank"
                                        >{ importedRecipe.url }</a>
                                    </div>
                                </div> : 
                                <div className="flex">
                                    {
                                        recipeImage && <div className="flex-shrink-0"><img src={recipeImage} className="w-14 lg:w-40 mt-2 rounded"/></div>
                                    }
                                    <div className="flex-grow pl-4">
                                        <div className="text-an_green-dark text-xl">{ recipeJson.name }</div>
                                        <div className="text-left flex-wrap flex-shrink-1 py-1 text-an_green-dark">
                                            {recipeJson.description}
                                        </div>
                                        <small className="text-left text-an_green-dark pb-2 block">
                                            {recipeJson.recipeYield ? `serves: ${recipeJson.recipeYield}` : ''}
                                        </small>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
        }
        </>
    )
}