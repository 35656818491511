import React, { useState, useEffect, useRef } from 'react'
import { ClockIcon as ClockiconSolid } from '@heroicons/react/solid'
import { ClockIcon } from '@heroicons/react/outline'

const Timer = ({ timerObj, restartTimer, pauseTimer, onClickTimer }) => {
    const [time, setTime] = useState(0)
    const [isRunning, setIsRunning] = useState(false)
    const intervalRef = useRef(null)


    const calculateSeconds = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(':').map(Number)
        return hours * 3600 + minutes * 60 + seconds
    }

    const formatTime = () => {
        const sign = time < 0 ? '-' : ''
        const absoluteTime = Math.abs(time)
        
        const minutes = Math.floor(absoluteTime / 60)
        const seconds = absoluteTime % 60
    
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = seconds.toString().padStart(2, '0')
    
        return `${sign}${formattedMinutes}:${formattedSeconds}`
    }

    useEffect(() => {
        setIsRunning(false)
    
        if (restartTimer) {
            setTime(calculateSeconds(timerObj?.start_time))
        }

        if(timerObj) {
            setTime(calculateSeconds(timerObj?.time_remaining))
        }
    }, [timerObj, restartTimer])

    const handleOnStartTimer = () => {
        setIsRunning(true)
        onClickTimer(true)
        
        intervalRef.current = setInterval(() => {
            setTime(prevTime => {
                if (prevTime === 0) {
                    const audio = new Audio('/notification.mp3')
                    audio.play()
                }

                return prevTime - 1
            })
        }, 1000)
    }

    const handleOnPauseTimer = () => {
        setIsRunning(false)
        clearInterval(intervalRef.current)
    }

    const handleOnRestartTimer = () => {
        setTime(0)
        clearInterval(intervalRef.current)
        
        if (isRunning) {
            handleOnStartTimer()
        }
    }

    useEffect(() => {
        return () => clearInterval(intervalRef.current)
    }, [])

    return (
        <div>
            {
                timerObj?.start_time ? 
                    <div onClick={isRunning ? handleOnPauseTimer : handleOnStartTimer}>
                        <div className="mx-auto w-6">
                        { 
                            isRunning && time >= 0
                                ? <ClockIcon className="w-6 h-6" /> 
                                : <ClockiconSolid className={`
                                    w-6 h-6 
                                    ${(time < 0) ? 'text-an_orange' : 'text-an_green'}
                                    ${!isRunning ? 'opacity-60' : 'text-an_green'}
                                `} /> 
                        }
                        </div>
                        <small className="w-full block text-center">{time ? formatTime() : '00:00:00'}</small>
                    </div> : <div></div>
            }
            {/* <button onClick={handleOnRestartTimer}>Restart</button> */}
        </div>
    )
}

export default Timer
