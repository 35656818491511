/** vendor */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { XIcon } from '@heroicons/react/outline'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'
import Loading from '../../components/UI/Loading'
import { Card } from '../../components/UI/Card'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'
import { removeSelfFromSharedList, setDefaultSharedStore } from '../../services/share.service'

/** state */
import { 
  deleteUserStore, 
  getSharedWithMe,
  getUserStores, 
  updateUserStore,
  setDefaultUserStore 
} from '../../actions/user.actions'

/** components */
import SearchSelectForm from '../../components/Forms/SearchSelectForm'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import FoodStoreOptions from '../../components/FoodStore/FoodStoreOptions'
import SelectUnitForm from '../../components/Forms/SelectUnitForm'
import FoodStoreActionBar from '../../components/FoodStore/FoodStoreActionBar'

export default function FoodStore() {
  // vendor utils
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // a9 utils
  const toast = useToast()
  const confirm = useConfirm()

  // ui states
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [storeItemsEdit, setStoreItemsEdit] = useState([])

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [focusSearch, setFocusSearch] = useState(false)
  const [showShare, setShowShare] = useState(false)

  // current food store
  const [foodstore, setFoodstore] = useState(null)
  const myFoodstore = useSelector(state => state.user_data?.user_stores?.find(s => s?.id == location.pathname.split('/')[2]))
  const sharedWithMeStore = useSelector(state => state?.user_data?.shared_with_me?.find(m => m.type === 'UserFoodStore' && m.id == location.pathname.split('/')[2]))

  // foodstore formatted for display
  const [groupedStoreItemsState, setGroupedStoreItemsState] = useState([])

  // shared with me store permissions
  const [isSharedStore, setIsSharedStore] = useState(false)
  const [canEditStore, setCanEditStore] = useState(true)
  const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)

  // form options
  const ingredientOptions = useSelector(state => state.form_options?.ingredient_options)
  const categoryOptions = useSelector(state => state?.form_options?.category_options)

  /**
   * Utils
   */
  const isExpired = (dateStr, estimated_expiry_days = 14) => {
    const date = new Date(dateStr)
    const today = new Date()
    date.setDate(date.getDate() + estimated_expiry_days)
    return  date < today 
  }

  const calcExpDate = (dateStr, estimated_expiry_days = 14) => {
    const date = new Date(dateStr)
    date.setDate(date.getDate() + estimated_expiry_days)

    return date
  }

  const formatExpDate = (dateStr, estimated_expiry_days = 14) => {
    return calcExpDate(dateStr, estimated_expiry_days = 14).toDateString()
  }

  const sortByDate = (itemsArray) => {
    return [...itemsArray].sort((a, b) => {
      if (new Date(a.date_added) !== new Date(b.date_added)) {
        return new Date(a.date_added) - new Date(b.date_added)
      } else {
        return a.id - b.id
      }
    })
  }
  
  const groupAndSortItems = (items, itemToAdd) => {
    const itemsCopy = [...items]
    const foundItem = itemsCopy.find((item) => 
      item.value === itemToAdd.value && 
      item.date_added.split('T')[0] === itemToAdd.date_added.split('T')[0]
    );

    if (foundItem) {
      foundItem.quantity++
    } else {
      itemToAdd['quantity'] = 1
      itemsCopy.push(itemToAdd)
    }

    return itemsCopy.sort((a, b) => {
      if (new Date(a.date_added) !== new Date(b.date_added)) {
        return new Date(a.date_added) - new Date(b.date_added)
      } else {
        return a.id - b.id
      }
    })
  }

  const updateStoreItems = (updatesItemsArray) => {
    setStoreItemsEdit(updatesItemsArray)

    const updatedFoodstore = {...foodstore}
    updatedFoodstore.items = updatesItemsArray
    setFoodstore(updatedFoodstore)

    let timerId = null
    const delay = 500
  
    clearTimeout(timerId)
  
    timerId = setTimeout(() => {
      setSaving(true)

      dispatch(updateUserStore(foodstore?.id, {items: updatesItemsArray})).then(() => {
        setSaving(false)
      })
    }, delay)
  }

  const setAllowedActions = () => {
    setIsSharedStore(true)

    let canEdit = false

    for(const user of sharedWithMeStore?.shared_with) {
        if(user?.email === currentUsersEmail && user?.canEdit) {
            canEdit = true
            break
        }
    }

    setCanEditStore(canEdit)
  }
  
  const setSharedDefaultStore = async (id) => {
    setLoading(true)

    try {
      await setDefaultSharedStore({entity_id: id, entity_type: 'UserFoodStore'})
      await dispatch(getSharedWithMe())
      dispatch(getUserStores())

      setLoading(false)
      setShowOptions(false)

      toast.open(`${foodstore?.title} set to default`)
    } catch (error) {
      toast.open(`An error Occureed`)
    }
  } 

  const setDefaultStore = async (id) => {
    setLoading(true)

    try {
      await dispatch(setDefaultUserStore(id))
      await dispatch(getUserStores())
      dispatch(getSharedWithMe())

      setLoading(false)
      setShowOptions(false)

      toast.open(`${foodstore?.name} set to default`)
    } catch (error) {
      toast.open(`An error Occureed`)
    }
  }
  
  const removeSharedList = async (id) => {
    setLoading(true)

    try {
      await removeSelfFromSharedList({entity_id: id, entity_type: 'UserFoodStore'})
      await dispatch(getSharedWithMe())

      setLoading(false)
      setShowOptions(false)

      toast.open(`${foodstore?.title} removed.`)
    } catch (error) {
      toast.open(`An error Occureed`)
    }
  } 

  const sortIntoCategories = (selectedStore) => {

    if(selectedStore?.items) {
      const listCategoryGroup = [];

      selectedStore?.items.forEach((ingredient) => {
        const groupItem = { category_name: '', category_id: null, ingredients: [] }

        if (ingredient?.id?.includes('CUSTOM')) {
            // it's a user created ingredient
            groupItem.category_id = 'CUSTOM'
            groupItem.category_name = 'Custom'
        } else {
            const ingredientOption = ingredientOptions?.find(i => i.value === ingredient?.value)
            
            if (ingredientOption?.category_id) {
                // it's a9 ingredient with a category id
                const categoryOption = categoryOptions?.find(c => c.value === ingredientOption?.category_id)
                groupItem.category_id = categoryOption?.value
                groupItem.category_name = categoryOption?.name
            } else {
                // it's a9 ingredient with no category id
                groupItem.category_id = 'MISC'
                groupItem.category_name = 'Misc'
            }
        }

        if (!listCategoryGroup?.find(i => i.category_id === groupItem.category_id))
        {
            listCategoryGroup.push(groupItem)
        }
      })

      selectedStore?.items.forEach((ingredient) => {         
        const ingredientOption = ingredientOptions?.find(i => i.value === ingredient?.value)
        const categoryOption = categoryOptions?.find(c => c.value === ingredientOption?.category_id)
        const listCategoryGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === categoryOption?.value)
    
        if (listCategoryGroupItemIndex > -1) {
            listCategoryGroup[listCategoryGroupItemIndex].ingredients.push(ingredient)
        } else {
            if (ingredient?.id?.includes('CUSTOM')) {
                const customGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === 'CUSTOM');
                listCategoryGroup[customGroupItemIndex].ingredients.push(ingredient)
            }
            else if (!ingredient?.category_id) {
                const miscGroupItemIndex = listCategoryGroup.findIndex(i => i['category_id'] === 'MISC');
                listCategoryGroup[miscGroupItemIndex].ingredients.push(ingredient)
            }
        }
      })

      setGroupedStoreItemsState(listCategoryGroup)

      const items = selectedStore?.items.sort((a, b) => {
        if (new Date(a.date_added) !== new Date(b.date_added)) {
          return new Date(a.date_added) - new Date(b.date_added)
        } else {
          return a.id - b.id
        }
      })

      setStoreItemsEdit(items)
    }

    setLoading(false)
  }

  /**
   * Hooks
   */
  useEffect(() => {
    if((myFoodstore || sharedWithMeStore) && !foodstore && ingredientOptions && categoryOptions) {
      const selectedStore = myFoodstore ?? sharedWithMeStore
      setFoodstore(selectedStore)
      setLoading(false)
    }

  }, [myFoodstore, sharedWithMeStore, ingredientOptions, categoryOptions])

  useEffect(() => {
      if (foodstore?.items?.length > 0) {
          sortIntoCategories(foodstore)
      } 
  }, [foodstore])

  /**
   * Handlers
   */
  const handleOnselectUnit = (newValue, item) => {
    const storeItems = [...storeItemsEdit]

    const indexToUpdate = storeItems.findIndex(i => item?.value === i?.value && item.date_added === i.date_added)
    
    storeItems[indexToUpdate]['unit'] = newValue
    updateStoreItems(storeItems)
  }

  const handleOnUpdateQuantity = (newValue, item) => {
    const storeItems = [...storeItemsEdit]
    const indexToUpdate = storeItems.findIndex(i => item?.value === i?.value && item.date_added === i.date_added)

    storeItems[indexToUpdate].quantity = newValue

    updateStoreItems(storeItems)
  }

  const handleOnSelectItem = (newValue, option) => {
    const itemToAdd = option
    
    itemToAdd['date_added'] = new Date().toISOString()

    const addItemArray = [...storeItemsEdit]
    const mappedItems = groupAndSortItems(addItemArray, itemToAdd)

    updateStoreItems(mappedItems)
  }
  
  const handleRemoveItem = (index, categoryIndex, ingredient) => {
    const removedItemArray = [...storeItemsEdit]
    const removeIndex = removedItemArray?.findIndex(
      i => i?.value === ingredient?.value && i?.date_added === ingredient?.date_added
    )

    if(removeIndex || removeIndex === 0) {
      removedItemArray.splice(removeIndex, 1)
      updateStoreItems(removedItemArray)
    }
  }

  const handleOnSearchFocus = () => {
    setFocusSearch(true)
  }
  
  const handleShowOptions = () => {
    setShowOptions(true)
  }

  const handleOnAction = async ({action, id}) => {
    if (action === 'delete')  {
      confirm.open(
        `Delete ${foodstore?.name}?`,
        'This action cannot be undone',
        async () => {
          setLoading(true)

          try {

            await dispatch(deleteUserStore(id))
            await dispatch(getUserStores())

            setLoading(false)
            setShowOptions(false)
            navigate('/user-stores')

          } catch (error) {
            setLoading(false)
          }
        }
      )    
    }

    if (action === 'share') {
      setShowOptions(false)
      setShowShare(true)
    }

    if (action === 'default') {
      await setDefaultStore(id)
    }

    if (action === 'remove') {
      removeSharedList(id)
    }
    
    if (action === 'shared_default') {
      setSharedDefaultStore(id)
    }
  }

  const handleOnBulkRemove = () => {
    // const selectedStore = myFoodstore ?? sharedWithMeStore
    setLoading(true)
    setFoodstore(null)
  }

  const handleOnShareUpdate = (share_with) => {}

  return (
    <div className="pt-12 w-full md:max-w-4xl mx-auto">
      <Card   
        title={`${foodstore ? foodstore?.name : 'Storage'}${foodstore?.default ? ` - (default)` : ''}`}
        status={
          <>
            {
              saving &&
              <div className="animate-pulse">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </div>
            }
            {
              canEditStore &&
                <button
                  className="text-xs h-6 w-6 text-an_white rounded-full"
                  onClick={ (e) => { handleShowOptions() } }
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                    </svg>
                </button>
            }
          </>
        }   
      >
        {
          loading && <div className="h-[60vh] relative pt-40"><Loading /></div>
        }

        {
          !loading &&
            <div>
              <div className="min-w-full">
                <FoodStoreActionBar foodstore={foodstore} itemsToManage={foodstore?.items} onRemove={handleOnBulkRemove} />
                <div className="border-t border-an_green-dark text-xs text-center">
                  { foodstore?.items?.length } item(s)
                </div>
                { 
                  foodstore?.name && 
                    <ul role="list" className="min-w-full">
                      {
                        groupedStoreItemsState &&
                          groupedStoreItemsState
                            .sort((a, b) => 
                              a?.category_name?.localeCompare(b?.category_name)
                            )
                            .map((category, categoryIndex) => { 
                              if (category?.ingredients?.length < 1) {
                                  return <></>
                              }

                              return (
                                  <li className="py-3 sm:py-4 min-w-full" key={categoryIndex}>
                                      <h2 className="p-2 text-green-dark text-lg">{category?.category_name}</h2>
                                      <ul role="list" className="min-w-full">
                                        {
                                          category?.ingredients?.sort((a, b) => 
                                            a?.name?.localeCompare(b?.name)
                                          )
                                          .map((ingredient, index) => { 
                                            return (
                                              <li 
                                                className="flex cursor-pointer justify-between py-2 odd:bg-an_white-dark hover:bg-an_white-darker1"
                                                key={index}
                                              >
                                                  <div className="flex items-center justify-between w-full px-2">
                                                      <div className="flex-shrink-0">
                                                          <input 
                                                              className="border w-16 py-0 text-center text-sm rounded-t" 
                                                              id="quantity" 
                                                              type="number"
                                                              disabled={!canEditStore}
                                                              onChange={(e) => { handleOnUpdateQuantity(e.target.value, ingredient) }}
                                                              value={ingredient?.quantity || 1} 
                                                          />
                                                          <div className="w-16">
                                                            <SelectUnitForm 
                                                              onSelectUnit={(newValue) => { handleOnselectUnit(newValue, ingredient) }} 
                                                              defaultUnit={ingredient?.default_unit ?? ingredient?.measurement} 
                                                              userUnit={ingredient?.unit}
                                                              canEdit={canEditStore} 
                                                            />
                                                          </div>
                                                      </div>
                                                      <div className="flex-1 flex-grow pl-2">
                                                          <p className={`'text-an_green-dark font-light'} text-lg`}>
                                                              {ingredient.name}
                                                          </p>

                                                          <small className={`text-an_green-dark font-light text-sm flex`}>
                                                            {
                                                              isExpired(ingredient?.date_added, ingredient?.estimated_expiry_days) 
                                                                ? <span className={`text-an_orange-dark font-normal`}>expired - {formatExpDate(ingredient?.date_added, ingredient?.estimated_expiry_days)} </span>
                                                                : <span >expires - {formatExpDate(ingredient?.date_added, ingredient?.estimated_expiry_days)} </span>
                                                            }
                                                          </small>
                                                      </div>
                                                      {
                                                        canEditStore &&
                                                          <div className="inline-flex items-right text-base font-semibold text-black">
                                                            <XIcon 
                                                              className="h-5 w-5 text-an_green-dark mr-2 mt-1 cursor-pointer" 
                                                              onClick={() => { handleRemoveItem(index, categoryIndex, ingredient) }} 
                                                            />
                                                          </div>
                                                      }
                                                  </div>
                                              </li>              
                                            )
                                          })
                                        }
                                      </ul>
                                  </li>
                              )
                          })
                      }
                    </ul>
                }
              </div>
            </div>
        }
      </Card>

      {
        canEditStore &&
          <div className={`${focusSearch ? 'h-screen bg-black' : ''} fixed bg-opacity-30 bottom-0 left-0 w-screen flex justify-center items-end pb-4 z-40`}>
            <div className="w-screen">
                <div className="fixed left-0 bottom-0 w-full">
                    {
                      focusSearch ?                                            
                      <button 
                          className="rounded z-50 w-8 h-8 mb-2 mr-2 bg-an_green-dark text-an_white block ml-auto"
                          onClick={(e) => { setFocusSearch(false) }}
                      >
                        <XIcon className="w-6 h-6 block mx-auto" />
                      </button>
                          : ''
                    }

                    {
                      ingredientOptions &&
                        <SearchSelectForm 
                          label="search"
                          buttonLabel="add"
                          options={ingredientOptions}
                          selectedOptions={storeItemsEdit} 
                          onSelectItem={(newValue, option) => { handleOnSelectItem(newValue, option) }}
                          handleOnSelectSearchFocus={handleOnSearchFocus}
                          showOptions={focusSearch}
                        />
                    }
                </div> 
            </div> 

            <ShareDialog
              showDialog={showShare}
              title={`Share my store`}
              shareWith={foodstore?.shared_with || []}
              entityId={foodstore?.id}
              entityType="UserFoodStore"
              onUpdate={handleOnShareUpdate}
              onCancel={() => { setShowShare(false) }}
            />

            <FoodStoreOptions
              isSharedWithUser={isSharedStore}
              foodstoreId={foodstore?.id}
              title={foodstore?.name}
              canEdit={canEditStore}
              isDefault={foodstore?.default} 
              showDialog={showOptions}
              onAction={handleOnAction}
              onCancel={() => { setShowOptions(false) }}
            />
          </div>
      }
    </div>
  )
}