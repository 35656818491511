import React, { useState } from 'react'

import { Card } from '../../components/UI/Card'
import { Carousel } from '../../components/UI/Carousel'
import Loading from '../../components/UI/Loading'
import { Link } from 'react-router-dom'
import Logo from '../../components/UI/Logo'

export default function Home() {
  const [loading, setLoading] = useState(false)

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-50 pt-16 bg-an_green-dark">
      <Logo />

      <h1 className="block text-white text-2xl font-light text-center w-full mb-4">Welcome to Aisle Nine</h1>
      <hr className="border-an_green-light" /> 
      <h2 className="block text-white text-xl font-light mb-2 text-center w-full mt-4">Closed beta is now live</h2>
      
      <div className="pt-12">
        <Link to="/register">
          <button 
              className={`${loading ? 'opacity-50' : ''} bg-an_green text-an_white px-4 rounded h-9 w-[200px] text-sm block mx-auto`} 
              type="button"
          >
              Register
          </button>
        </Link>
      </div>

      <div className="pt-4">
        <Link to="/login">
          <button 
              className={`${loading ? 'opacity-50' : ''} bg-an_green text-an_white px-4 rounded h-9 w-[200px] text-sm block mx-auto`} 
              type="submit"
          >
              Login
          </button>
        </Link> 
      </div>
      
    </div>
  )
}