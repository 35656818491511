import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

export default function TagIcons({
    fodMapWarning = false,
    allergenWarning,
    vegetarian,
    vegan,
    allergenDialog,
    glutenFree,
    sugarFree,
    dairyFree

}) {
    return (
        <div className="flex justify-center">
            { 
                allergenWarning && 
                    <div className="mr-1 mt-[-6px] hover:bg-an_green-dark rounded hover:text-an_white p-1" onClick={allergenDialog}>
                        <ExclamationCircleIcon className="h-6 w-6 mx-auto block  hover:text-an_white"/>
                        <small className="text-[6px] block text-center mt-[-3px]">allergens</small>
                    </div> 
            }
            { 
                vegetarian && !vegan && 
                    <span className="border border-an_green-dark py-1 mr-2 text-an_green-dark text-xs w-6 h-6 text-center rounded-full">V</span> 
            }
            { 
                vegan && 
                    <span className="border border-an_green-dark py-1 mr-2 text-an_green-dark text-xs w-6 h-6 text-center rounded-full">VG</span> 
            }
            { 
                dairyFree && 
                    <span className="border border-an_green-dark py-1 mr-2 text-an_green-dark text-xs w-6 h-6 text-center rounded-full">DF</span> 
            }
            { 
                glutenFree && 
                    <span className="border border-an_green-dark py-1 mr-2 text-an_green-dark text-xs w-6 h-6 text-center rounded-full">GF</span> 
            }
            { 
                sugarFree && 
                    <span className="border border-an_green-dark py-1 text-an_green-dark text-xs w-6 h-6 text-center rounded-full">SF</span> 
            }
        </div>
    )
}