/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { EyeIcon, CakeIcon } from '@heroicons/react/solid'

/** lib */
import { formatShortDate } from '../../utils'
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'
import { removeSelfFromSharedList, setDefaultSharedStore } from '../../services/share.service'

/** state */
import { deleteUserStore, getUserStores, createUserStore, setDefaultUserStore, getSharedWithMe } from '../../actions/user.actions'

/** components */
import ShareDialog from '../../components/Dialogs/ShareDialog'
import CreateStoreDialog from '../../components/Dialogs/CreateStoreDialog'
import FoodStoreOptions from '../../components/FoodStore/FoodStoreOptions'

export default function FoodStoreList() {
  // vendor utils
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [showCreateDialog, setShowCreateDialog] = useState(false)

  // a9 utils
  const confirm = useConfirm()
  const toast = useToast()

  // ui states
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('mystores')

  // stores
  const foodstores = useSelector(state => state.user_data.user_stores)
  const sharedWithMeStores = useSelector(state => state.user_data.shared_with_me.filter(m => m.type === 'UserFoodStore'))
  const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)

  const [foodstoreContext, setFoodstoreContext] = useState({
    id: null,
    title: '',
    isSharedStore: false,
    canEditStore: true,
    default: false
  })

  const setSharedDefaultStore = async (id) => {
    setLoading(true)

    try {
      await setDefaultSharedStore({entity_id: id, entity_type: 'UserFoodStore'})
      await dispatch(getSharedWithMe())
      dispatch(getUserStores())

      setLoading(false)
      setShowOptions(false)

      toast.open(`${foodstoreContext?.title} set to default`)
    } catch (error) {
      toast.open(`An error Occureed`)
    }
  }

  const setDefaultStore = async (id) => {
    setLoading(true)

    try {
      await dispatch(setDefaultUserStore(id))
      await dispatch(getUserStores())
      dispatch(getSharedWithMe())

      setLoading(false)
      setShowOptions(false)

      toast.open(`${foodstoreContext?.title} set to default`)
    } catch (error) {
      toast.open(`An error Occureed`)
    }
  } 

  const removeSharedList = async (id) => {
    setLoading(true)

    try {
      await removeSelfFromSharedList({entity_id: id, entity_type: 'UserFoodStore'})
      await dispatch(getSharedWithMe())

      setLoading(false)
      setShowOptions(false)

      toast.open(`${foodstoreContext?.title} removed.`)
    } catch (error) {
      toast.open(`An error Occureed`)
    }
  }

  /**
   * Hooks
   */
  useEffect(() => {
      setLoading(true)
      
      dispatch(getUserStores()).then((r) => {
          setLoading(false)
      }).catch(() => {
          setLoading(false)
      })
  }, [])

  /**
   * Handlers
   */
  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  const handleOnAction = async (action, id) => {
    if (action === 'delete') {

      confirm.open(
        `Delete ${foodstoreContext?.title}?`,
        'This action cannot be undone',
        async () => {
          setLoading(true)

          await dispatch(deleteUserStore(id))
          await dispatch(getUserStores())
          
          setShowOptions(false)
          setLoading(false)
          toast.open('Store Deleted.')
        }
      )
    }

    if (action === 'remove') {
      removeSharedList(id)
    }

    if (action === 'share') {
      setShowOptions(false)
      setShowShare(true)
    }

    if (action === 'view') {
      navigate(`/user-stores/${id}?view=readonly`)  
    }

    if (action === 'edit') {
      navigate(`/user-stores/${id}`)  
    }

    if (action === 'default') {
      await setDefaultStore(id)
    }

    if (action === 'shared_default') {
      setSharedDefaultStore(id)
    }
    
  }

  const handleShowOptions = (foodstore) => {
    const isSharedStore = tab !== 'mystores' 
    let canEdit = !isSharedStore

    if(isSharedStore) {
      for(const user of foodstore?.shared_with) {
        if(user?.email === currentUsersEmail && user?.canEdit) {
            canEdit = true
            break
        }
      }
    }

    setFoodstoreContext({
      id: foodstore.id,
      title: foodstore.name,
      shared_with: foodstore.shared_with,
      isSharedStore: isSharedStore,
      canEditStore: canEdit,
      default: foodstore?.default
    })

    setShowOptions(true)
  }

  const handleOnShareUpdate = (share_with) => {
    // @todo use new share controller
  }

  const handleCreateStore = (payload) => {
    setLoading(true)

    dispatch(createUserStore(payload)).then(() => {
      dispatch(getUserStores()).then(() => {
        setLoading(false)
        setShowCreateDialog(false)
      })
    })
  }

  return (
    <div className="pt-12 w-full md:max-w-4xl mx-auto">
      <Card title="Stores">
        {
          loading && <div className="h-[60vh] relative pt-40"><Loading /></div>
        }

        {
          !loading &&
            <div>
              <div className="w-full flex flex-col items-center">
                <ul className="w-full border-b-2 pt-2 border-an_orange-dark flex justify-center flex-wrap text-sm font-medium text-center">
                  <li className="px-1 w-1/2">
                    <a
                      href="#mystores"
                      aria-current="page"
                      onClick={handleToggleTab}
                      className={`${tab === 'mystores' ? 'active bg-an_orange border-an_orange-dark text-white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                    >
                      my stores
                    </a>
                  </li>
                  <li className="px-1 w-1/2">
                    <a
                      href="#shared"
                      onClick={handleToggleTab}
                      className={`${tab === 'shared' ? 'active bg-an_orange border-an_orange-dark text-white text-border-orange' : 'text-an_orange-dark'} block py-1 px-2 rounded-t`}
                    >
                      shared with me
                    </a>
                  </li>
                </ul>
              </div>

              {
                tab === 'shared' && 
                  <div>
                    <ul className="w-full">
                      { 
                        (sharedWithMeStores && sharedWithMeStores.length > 0) ?
                          sharedWithMeStores.map((store, i) => 
                            <li
                              className="flex cursor-pointer justify-between py-2 odd:bg-an_white-dark hover:bg-an_white-darker1" 
                              key={i}
                              onClick={ () => navigate(`/user-stores/${store?.id}`)}
                            >
                                <div className="">
                                  <small className="block ml-2 text-an_green-dark" style={{ fontSize: 12 }}>Last Updated: {new Date(store?.updated_at).toLocaleString()}</small>
                                  <div className="flex items-center">
                                    {
                                      store?.default &&
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 ml-2 mt-1 text-an_tumeric">
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                                        </svg>  
                                    }
                                    <label className="block ml-2 mt-1 text-lg text-an_green-dark">{store?.name}</label>
                                  </div>
                                </div>
                                
                                <div className="text-center pr-2 pt-2">
                                  <div className="flex justify-end items-center">
                                    <button
                                      className="text-xs h-6 ml-auto px-1 border border-an_green-dark text-an_green-dark bg-an_white-dark rounded-full"
                                      onClick={ 
                                        (e) => {
                                          e.stopPropagation() 
                                          handleShowOptions(store) 
                                        } 
                                      }
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                      </svg>

                                    </button>
                                  </div>
                                  <small className="leading-none block text-an_green-dark pt-1" style={{ fontSize: 12 }}>{store?.items?.length} items</small>
                                </div>
                            </li>
                          ) :   
                          <li className="text-center pt-40">
                              <p className="text-center py-4 text-an_green-dark">nada</p>
                          </li> 
                      }
                    </ul>
                  </div> 
              }

              {
                tab === 'mystores' &&
                  <ul className="w-full">
                    { 
                      (foodstores && foodstores.length > 0) ?
                        foodstores.sort((a) => a.default ? -1 : 1).map((store, i) => 
                          <li 
                            className="flex cursor-pointer justify-between py-2 odd:bg-an_white-dark hover:bg-an_white-darker1" 
                            key={i}
                            onClick={ () => navigate(`/user-stores/${store?.id}`)}
                          >
                            <div className="">
                              <small className="block ml-2 text-an_green-dark" style={{ fontSize: 12 }}>Last Updated: {new Date(store?.updated_at).toLocaleString()}</small>
                              <div className="flex items-center">
                                {
                                  store?.default &&
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 ml-2 mt-1 text-an_tumeric">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
                                    </svg>  
                                }
                                <label className="block ml-2 mt-1 text-lg text-an_green-dark">{store?.name}</label>
                              </div>
                            </div>

                            <div className="text-center pr-2 pt-2">
                              <div className="flex justify-end items-center">
                                <button
                                  className="text-xs h-6 w-6 ml-auto border border-an_green-dark text-an_green-dark bg-an_white-dark hover:bg-an_green-dark hover:text-an_white-dark active:bg-an_green-dark active:text-an_white-dark rounded-full"
                                  onClick={ 
                                    (e) => {
                                      e.stopPropagation() 
                                      handleShowOptions(store) 
                                    } 
                                  }
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mx-auto">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                  </svg>

                                </button>
                              </div>
                              <small className="leading-none block text-an_green-dark pt-1" style={{ fontSize: 12 }}>{store?.items?.length} items</small>
                            </div>
                          </li>
                        ) :  
                        <li className="text-center pt-40">
                          <p className="text-center py-4 text-an_green-dark">nope</p>
                        </li> 
                    }
                  </ul> 
              }
            </div>
        }
      </Card>

      {
        !showCreateDialog &&
          <div className="fixed bottom-0 left-0 w-screen pb-4 flex justify-center">
            <button
              onClick={() => { setShowCreateDialog(true) }} 
              type="button" 
              className="bg-an_orange text-border-orange w-32 text-an_white rounded font-medium border border-an_orange-darker3 text-sm px-5 py-2"
              >Create</button>
          </div>
      }

      <CreateStoreDialog 
        showDialog={showCreateDialog} 
        store={null}
        onSubmit={handleCreateStore} 
        onCancel={() => { setShowCreateDialog(false) }} 
      />

      {
        foodstoreContext?.canEditStore &&
          <FoodStoreOptions 
            foodstoreId={foodstoreContext?.id} 
            title={foodstoreContext?.title}
            canEdit={foodstoreContext?.canEditStore}
            isDefault={foodstoreContext?.default}
            isSharedWithUser={foodstoreContext?.isSharedStore} 
            showDialog={showOptions}
            onAction={handleOnAction}
            onCancel={ () => { setShowOptions(false) } } 
          />
      }

      {
        !foodstoreContext?.isSharedStore &&
          <ShareDialog
            showDialog={showShare}
            entityId={foodstoreContext?.id}
            entityType="UserFoodstore"
            title={`Share ${foodstoreContext?.title}`}
            shareWith={foodstoreContext?.shared_with}
            onUpdate={handleOnShareUpdate}
            onCancel={ () => { setShowShare(false) } } 
          />
      }
    </div>
  )
}